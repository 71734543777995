import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";

function General({ customerPlans, formDetail, handleFormSubmit }) {
  console.log(customerPlans)

   //state for General
  const [general, setGeneral] = useState({
    name: "",
    description: "",
    price: "",
    frequency_amount: "",
    frequency_unit: "",
    billing_cycle: "",
    currency_id: "",
    show_in_customer: "",
    tax_billing_required: "",
    is_popular: "",
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    setGeneral((prev) => ({
      ...prev,
      name: customerPlans?.plan?.name,
      description: customerPlans?.plan?.description,
      price: customerPlans?.plan?.price,
      frequency_amount: customerPlans?.plan?.frequency_amount,
      frequency_unit: customerPlans?.plan?.frequency_unit,
      billing_cycle: customerPlans?.plan?.billing_cycle,
      currency_id: customerPlans?.plan?.currency_id,
      show_in_customer: customerPlans?.plan?.show_in_customer,
      tax_billing_required: customerPlans?.plan?.tax_billing_required,
      is_popular: customerPlans?.plan?.is_popular,
    }));
  }, [customerPlans]);

  //onchange function for general state
  const onChangeFun = (e, name) => {
    setGeneral((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };
  //onchange function for selectbox of general
  const onChangeSelectBox1 = (e, name) => {
    setGeneral((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };
  //onchange function for general checkbox
  const onChangeCheckBox1 = (e, name, togFun) => {
    setGeneral((prev) => {
      return {
        ...prev,
        [name]: e.target.value === "true" ? false : true,
      };
    });
    togFun();
  };

  //checked state for general
  const [checked01, setchecked01] = useState<boolean>();
  const [checked02, setchecked02] = useState<boolean>();
  const [checked03, setchecked03] = useState<boolean>();

  //toggle function that  wil toggle check and uncheck
  const togFun01 = () => {
    setchecked01(!checked01);
  };
  const togFun02 = () => {
    setchecked02(!checked02);
  };
  const togFun03 = () => {
    setchecked03(!checked03);
  };

  //It is needed because i want some other state variable as a inital value of another state
  useEffect(() => {
    setchecked01(customerPlans?.plan?.show_in_customer);
    setchecked02(customerPlans?.plan?.tax_billing_required);
    setchecked03(customerPlans?.plan?.is_popular);
  }, [customerPlans]);

  return (
    <>
      <div className="bg-[white] shadow-md rounded-md flex flex-col">
        <form
          onSubmit={(e) => {
            handleFormSubmit(
              e,
              formDetail.formGeneral.url,
              general,
              formDetail.formGeneral._method,
              formDetail.formGeneral._token
            );
            console.log(general);
          }}
        >
          <div className="mt-4">
            <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Name<span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[40%]"
                value={general.name}
                onChange={(e) => onChangeFun(e, "name")}
                required
              />
            </div>
            <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Description
              </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[40%] "
                value={general?.description}
                onChange={(e) => onChangeFun(e, "description")}
              />
            </div>
            <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Price<span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[40%] "
                value={general?.price}
                onChange={(e) => onChangeFun(e, "price")}
                required
              />
            </div>
            <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Billiing cycle<span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[40%] "
                value={general?.billing_cycle}
                onChange={(e) => onChangeFun(e, "billing_cycle")}
                required
              />
            </div>
            <div className="w-[100%] md:w-[50%] mt-2  flex flex-col md:flex-row mb-[1rem] gap-4 md:gap-0">
              <div className="flex-1  flex flex-col mx-4">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Frequency Amount<span className="text-red-600"> *</span>
                </label>
                <input
                  type="text"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[62%] "
                  value={general?.frequency_amount}
                  onChange={(e) => onChangeFun(e, "frequency_amount")}
                  required
                />
              </div>
              <div className="flex-1  flex flex-col  mx-4">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Frequency Unit<span className="text-red-600"> *</span>
                </label>
                <select
                  name=""
                  id=""
                  value={general?.frequency_unit}
                  onChange={(e) => onChangeSelectBox1(e, "frequency_unit")}
                  className="rounded-md px-[1rem] text-[--accent-gray] md:w-[62%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
                >
                  {["Day", "Week", "Month", "Year"].map((option, key) => (
                    <option key={key} value={option.toLowerCase()}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="md:w-[79%] mt-2 flex flex-col mb-[1rem] mx-4">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                currency_id
              </label>
              <select
                name=""
                id=""
                value={general?.currency_id}
                onChange={(e) => onChangeSelectBox1(e, "currency_id")}
                className="rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
              >
                {customerPlans?.currencies?.map((option, key) => (
                  <option key={key} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-4 mx-4 flex flex-col gap-4">
            <div className="">
              <input
                className="mx-1"
                type="checkbox"
                id="inp2"
                checked={checked01}
                value={general?.show_in_customer}
                onChange={(e) =>
                  onChangeCheckBox1(e, "show_in_customer", togFun01)
                }
              />
              <label
                htmlFor="inrounded-md p1"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Show in Customer
              </label>
              <p className="mx-1 text-sm opacity-40"></p>
            </div>

            <div className="">
              <input
                className="mx-1"
                type="checkbox"
                id="inp3"
                checked={checked02}
                value={general?.tax_billing_required}
                onChange={(e) =>
                  onChangeCheckBox1(e, "tax_billing_required", togFun02)
                }
              />
              <label
                htmlFor="inrounded-md p2"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Billing information required?
              </label>
              <p className="mx-1 text-sm opacity-40">
                Ask for tax billing information when subscribing to the plan
              </p>
            </div>

            <div className="">
              <input
                className="mx-1"
                type="checkbox"
                id="inp4"
                checked={checked03}
                value={general?.is_popular}
                onChange={(e) => onChangeCheckBox1(e, "is_popular", togFun03)}
              />

              <label
                htmlFor="inrounded-md p3"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Set as popular?
              </label>

              <p className="mx-1 text-sm opacity-40">
                Set this plan as a popular plan
              </p>
            </div>

            <div className="mt-4 mb-6 ">
              <button
                type="submit"
                className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
              >
                <TfiSave className="mr-1" />
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default General;
