import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Console } from "console";
import { AiOutlineDelete } from "react-icons/ai";
import { FiAlertCircle, FiCopy } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import { apiBaseUrl } from "../utils/urls";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";

const DialogBox = ({ title,deleteUrl}) => {
  const token = getToken();
  
const handleDel = async()=>{
  try {
    const response = await axios.delete(
      `${apiBaseUrl}${deleteUrl}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type":'application/x-www-form-urlencoded'
        },
      }
    );

    showSuccessfulToastAlert(response?.data.message);
  } catch (error) {
    showFailedToastAlert(error);
  }
};


  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    console.log("okkkkkkk");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log("cancccc");
  };

  return (
    <>
      {title === "delete" ? (
        <AiOutlineDelete
          onClick={() => setIsModalOpen(true)}
          className="text-[#EA5455] cursor-pointer "
          size={25}
        />
      ) : (
        <FiCopy
          onClick={() => setIsModalOpen(true)}
          className="text-[#35cb78] cursor-pointer"
          size={25}
        />
      )}

      {title === "delete" ? (
        <Modal
          // title="hiii"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="flex flex-col gap-4 justify-center items-center">
            <FiAlertCircle className="text-[#F9C395]" size={80} />
            <label className="text-2xl font-[600] text-[var(--accent-gray)] ">
              Are you sure ?
            </label>
            <p className="text-[var(--accent-gray)] text-xl ">
              You would not be able to revert this!
            </p>
            <div className="flex items-center justify-center gap-2">
              <button onClick={()=>{handleDel(); handleOk()}} className="bg-[var(--accent-orange)] border-2 border-[#f99004] text-[#fff] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] font-[500]  ">
                Yes,delete it!
              </button>
              <button
                onClick={handleCancel}
                className=" font-[500] bg-white border-2 border-[#ea5455] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] text-[#EA5455] "
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          // title="hiii"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="flex flex-col gap-4 justify-center items-center">
            <label className="text-2xl font-[600] text-[var(--accent-gray)] ">
              Name your new plan
            </label>
            <p className="text-[var(--accent-gray)] text-xl ">
              What would you like to name your Plan?
            </p>
            <input
              className="bg-white outline-1 w-full outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
            />
            <div className="flex gap-2">
              <button className="bg-[var(--accent-orange)] flex justify-center items-center gap-2 border-2 border-[#f99004] text-[#fff] py-[0.7rem] px-[1.5rem] rounded-[0.358rem]  font-[500] ">
                <MdOutlineContentCopy />
                Copy
              </button>
              {/* <button className="accent-btn font-[500] ">Cancel</button> */}
              <button
                onClick={handleCancel}
                className=" font-[500] bg-white border-2 border-[#ea5455] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] text-[#EA5455] "
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DialogBox;
