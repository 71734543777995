import { Switch } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import { useState, useEffect } from "react";
import { TfiSave } from "react-icons/tfi";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import { BiSave } from "react-icons/bi";

function EditSendingServerNSES({ formState, sendingServerData }: any) {
  const token = getToken(); // Token for authorizations

  // state variales
  const [editSendingForm, setEditSendingForm] = useState<any>({
    method: "PUT",
    token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
    name: "",
    api_link: "",
    auth_token: "",
    quota_value: "",
    quota_base: "",
    quota_unit: "",
    sms_per_request: "",
    plain: "",
    schedule: "",
    settings: "",
    type: "http",
  });

  // Update  editSendingForm state when components renders
  useEffect(() => {
    setEditSendingForm((prev) => ({
      ...prev,
      name: sendingServerData?.server?.name,
      api_link: sendingServerData?.server?.api_link,
      api_key: sendingServerData?.server?.api_key,
      auth_token: "",
      quota_value: sendingServerData?.server?.quota_value,
      quota_base: sendingServerData?.server?.quota_base,
      quota_unit: sendingServerData?.server?.quota_unit,
      sms_per_request: sendingServerData?.server?.sms_per_request,
      plain: sendingServerData?.server?.plain,
      schedule: sendingServerData?.server?.schedule,
      settings: sendingServerData?.server?.settings,
      type: "http",
    }));
  }, [sendingServerData]);

  // Updates the editSendingForm state when an input field value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditSendingForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the editSendingForm state when an select element field value changes
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditSendingForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the editSendingForm state when an switch element field value changes
  const handleSwitchChange = (e, name: string) => {
    setEditSendingForm((prev) => ({
      ...prev,
      [name]: !editSendingForm?.[name],
    }));
  };

  // Handles form submission asynchronously
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      const data = JSON.stringify(editSendingForm); //Converts serverData to a JSON string
      // Configuration object for the HTTP request
      let config: AxiosRequestConfig = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/sending-servers/${sendingServerData?.server?.uid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data, // Includes the JSON data in the request body
      };
      const response = await axios.request(config); // Sends the HTTP request using axios
      showSuccessfulToastAlert(response?.data.message); // Shows a success toast alert with the response message
    } catch (error) {
      showFailedToastAlert(error); // Shows a failed toast alert with the error
    }
  };

  // console.log(sendingServerData, "props-sendingServerData");
  // console.log(formState, "props_value");

  return (
    <form onSubmit={handleSubmit}>
      <div className="main  my-[15px] font-sans flex md:flex-row flex-col gap-4 w-[100%]">
        
        <div className="flex flex-col w-[100%]   gap-8">
          {/*update credential cont*/}
          <div className="bg-white shadow-md rounded-md md:w-[60%] w-[100%]">
            <h3 className="text-[#5E5873] font-medium mx-4 my-4">
              Update Credentials
            </h3>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label
                htmlFor="name"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Name<span className="text-red-600"> *</span>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={editSendingForm?.name}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%] "
              />
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label
                htmlFor="api_link"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                API Link<span className="text-red-600"> *</span>
              </label>
              <input
                id="api_link"
                name="api_link"
                type="text"
                value={editSendingForm?.api_link}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              />
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Auth Token(api_key)<span className="text-red-600"> *</span>
              </label>
              <input
                id="api_key"
                name="api_key"
                type="text"
                value={editSendingForm?.api_key}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              />
            </div>
          </div>
          {/*sending limit cont*/}
          <div className="bg-white shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[60%] w-[100%]">
            <div className="mb-4">
              <h3 className="text-[#5E5873] font-medium  mx-4 my-4 ">
                Sending Credit
              </h3>
              <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
                The configuration setting below allows you to set a limit on sms
                sending speed. For example, to limit sending speed to{" "}
                <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                  500 sms every 5 minutes
                </span>{" "}
                , you can set{" "}
                <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                  Sending limit = 500, Time value = 5, and Time unit ={" "}
                </span>{" "}
                accordingly. Also set{" "}
                <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                  amount
                </span>{" "}
                of{" "}
                <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                  several messages
                </span>{" "}
                with a{" "}
                <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                  single HTTP request.
                </span>
              </p>
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label
                htmlFor="quota_value"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Sending Credit<span className="text-red-600"> *</span>
              </label>
              <input
                id="quota_value"
                name="quota_value"
                type="number"
                value={editSendingForm?.quota_value}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              />
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label
                htmlFor="quota_base"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Time Base<span className="text-red-600"> *</span>
              </label>
              <input
                id="quota_base"
                name="quota_base"
                type="number"
                value={editSendingForm?.quota_base}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              />
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Time Unit<span className="text-red-600"> *</span>
              </label>
              <select
                id="quota_unit"
                name="quota_unit"
                value={editSendingForm?.quota_unit}
                onChange={handleSelectChange}
                className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
              >
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
              </select>
            </div>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                SMS Per Single Unit<span className="text-red-600"> *</span>
              </label>
              <input
                id="sms_per_request"
                name="sms_per_request"
                type="number"
                value={editSendingForm?.sms_per_request}
                onChange={handleInputChange}
                className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              />
            </div>
          </div>
          {/*Availale Feature Container started*/}
          <div className="bg-white shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[60%] w-[100%]">
            <h3 className="text-[#5E5873] font-medium  mx-4 my-4">
              Available Features
            </h3>
            <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
              <div className="flex  gap-6">
                <div className="flex flex-col ">
                  <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                    Plain<span className="text-red-600"> *</span>
                  </label>
                  <div>
                    <Switch
                      onChange={(e) => handleSwitchChange(e, "plain")}
                      checked={editSendingForm?.plain}
                      // className="text-[--accent-orange]"
                      style={{
                        backgroundColor: editSendingForm.plain
                          ? "#f08b38"
                          : "#E2E2E2",
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="text-[0.857rem] w-max text-[--accent-gray] mb-[0.2857rem]">
                    Schedule<span className="text-red-600"> *</span>
                  </label>
                  <div>
                    <Switch
                      onChange={(e) => handleSwitchChange(e, "schedule")}
                      checked={editSendingForm?.schedule}
                      // className="text-[--accent-orange]"
                      style={{
                        backgroundColor: editSendingForm.schedule
                          ? "#f08b38"
                          : "#E2E2E2",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mt-4 justify-end">
                <button className="accent-btn">
                  <BiSave size={15} />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditSendingServerNSES;
