import React from "react";
import { ImHome } from "react-icons/im";
import { BsPersonFill, BsFillCreditCardFill } from "react-icons/bs";

const RegisterNav = (props: any) => {
  const { data } = props;
  let icons: any;
  switch (data?.title) {
    case "Account":
      icons = <ImHome />;
      break;
    case "Personal":
      icons = <BsPersonFill />;
      break;
    case "Billing":
      icons = <BsFillCreditCardFill />;
      break;
    default:
      icons = <ImHome />;
      break;
  }
  return (
    <div className="mt-10 flex items-center gap-2">
      <div
        className={`text-2xl  p-2 rounded-md ${
          data?.active ? "bg-orange-500 text-white" : "bg-gray-300 text-white"
        }  `}
      >
        {icons}
      </div>
      <div className="flex flex-col">
        <span className="text-orange-500">{data?.title}</span>
        <span className="opacity-30">{data?.desc}</span>
      </div>
    </div>
  );
};

export default RegisterNav;
