import { Select } from "antd";
import React, { useEffect, useState } from "react";
import FormSkeleton from "../FormSkeleton";
import DataTable from "react-data-table-component";
import axios from "axios";
import parser from "html-react-parser";
import { getData } from "./getData";
import { useNavigate, useParams } from "react-router-dom";
import { getEomRecordData } from "./getEomRecordData";
import { showFailedToastAlert } from "../../utils/toastAlerts";
import { getToken } from "../../utils/tokentUtils";
import ViewMessageModalComponent from "../ViewMessageModalComponent";
import HandlingPagination from "../HandlingPagination";

const EomRecordData = () => {
  const [next, setNext] = useState<any>(0);
  const [noOfRows, setNoOfRows] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [allData, setAllData] = useState<any>();
  const [dataForTable, setTable] = useState<any>();
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });

  const { id } = useParams();
  useEffect(() => {
    getEomRecordData(next, setAllData, showFailedToastAlert, id);
  }, []);

  const token = getToken();
  const navigate = useNavigate();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const handleChange = ({ selectedRows }) => {
    const ids = selectedRows.map((element) => {
      return element.uid;
    });
    console.log(ids);
    // setId(ids);
  };
  const modalHandle = (id: any) => {
    // console.log(id);
    setModal({ open: !modal?.open, id: id });
  };
  const handleSearchChange = (e: any) => {
    e.preventDefault();
    setSearchQuery(e?.target?.value);
  };

  const handleShow = (id: number) => {};

  //   const searchData = () => {
  //     console.log(searchQuery);
  //     let data = JSON.stringify({
  //       length: 10,
  //       start: 0,
  //       orderBy: "id",
  //       orderDir: "ASC",
  //       search: searchQuery,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${baseUrl}/customers/eom_records/search`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         // console.log(JSON.stringify(response.data));
  //         const data = response?.data;
  //         console.log("search", data);
  //         if (data?.status === "success") {
  //           setAllData(data.data);
  //         } else {
  //           showFailedToastAlert(data?.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("Error search query", error);
  //         showFailedToastAlert(error?.message);
  //       });
  //   };

  const columns: any = [
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "From",
      selector: (row) => row.data.from,
    },
    {
      name: "To",
      selector: (row) => row.data.to,
    },
    {
      name: "Action",
      selector: (row: any) => {
        // console.log(row);
        return (
          <div className=" flex items-center gap-3 justify-center ">
            <div
              onClick={() => {
                modalHandle(row?.data.uid);
              }}
            >
              <svg
                fill="#F79236"
                width="19px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5.5A2.59 2.59 0 0 0 5.33 8 2.59 2.59 0 0 0 8 10.5 2.59 2.59 0 0 0 10.67 8 2.59 2.59 0 0 0 8 5.5zm0 3.75A1.35 1.35 0 0 1 6.58 8 1.35 1.35 0 0 1 8 6.75 1.35 1.35 0 0 1 9.42 8 1.35 1.35 0 0 1 8 9.25z" />
                <path d="M8 2.5A8.11 8.11 0 0 0 0 8a8.11 8.11 0 0 0 8 5.5A8.11 8.11 0 0 0 16 8a8.11 8.11 0 0 0-8-5.5zm5.4 7.5A6.91 6.91 0 0 1 8 12.25 6.91 6.91 0 0 1 2.6 10a7.2 7.2 0 0 1-1.27-2A7.2 7.2 0 0 1 2.6 6 6.91 6.91 0 0 1 8 3.75 6.91 6.91 0 0 1 13.4 6a7.2 7.2 0 0 1 1.27 2 7.2 7.2 0 0 1-1.27 2z" />
              </svg>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="shadow-sm rounded-xl shadow-black h-[80vh] m-10">
      <ViewMessageModalComponent
        modalHandle={modalHandle}
        modalOpen={modal?.open}
        messageId={modal?.id}
      />
      <div className="bg-white">
        <div className=" mt-[16px] pt-[23px] pb-[12px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
          <div className="flex items-center">
            <h2 className="text-[var(--primary-color)] text-[18px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
              EOM Records
            </h2>
            <Select
              onChange={(value: any) => setNoOfRows(value)}
              options={[
                { value: 10 },
                { value: 20 },
                { value: 50 },
                { value: 100 },
              ]}
              style={{ width: "100px", marginLeft: "15px" }}
              defaultValue={10}
            />
          </div>
        </div>
        {/* <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
          <div
            className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between"
            onClick={searchData}
          >
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              className="pt-[14px] pl-[14px] pb-[13px] outline-none bg-white placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
            />
            <img src={search} alt="" className="cursor-pointer" />
          </div>
        </div> */}

        {!allData ? (
          <FormSkeleton titles={["Name", "Current Plan", "Status", "Action"]} />
        ) : (
          <DataTable
            columns={columns}
            data={dataForTable}
            selectableRows
            fixedHeader
            highlightOnHover
            onSelectedRowsChange={handleChange}
            selectableRowsHighlight
          />
        )}

        <HandlingPagination
          data={allData?.data}
          getDataForTable={getDataForTable}
        />
      </div>
    </div>
  );
};

export default EomRecordData;
