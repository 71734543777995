import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";

function PlanFeatures({ customerPlans, formDetail, handleFormSubmit }) {
  //state for plan features
  const [planForm, setPlanForm] = useState({
    sms_max: "",
    list_max: "",
    subscriber_max: "",
    subscriber_per_list_max: "",
    segment_per_list_max: "",
    list_import: "",
    list_export: "",
    api_access: "",
    create_sending_server: "",
    create_sub_account: "",
    delete_sms_history: "",
    sender_id_verification: "",
    send_spam_message: "",
    cutting_system: "",
    add_previous_balance: "",
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    setPlanForm((prev) => ({
      ...prev,
      sms_max: customerPlans?.options?.sms_max,
      list_max: customerPlans?.options?.list_max,
      subscriber_max: customerPlans?.options?.subscriber_max,
      subscriber_per_list_max: customerPlans?.options?.subscriber_per_list_max,
      segment_per_list_max: customerPlans?.options?.segment_per_list_max,
      list_import: customerPlans?.options?.list_import,
      list_export: customerPlans?.options?.list_export,
      api_access: customerPlans?.options?.api_access,
      create_sending_server: customerPlans?.options?.create_sending_server,
      create_sub_account: customerPlans?.options?.create_sub_account,
      delete_sms_history: customerPlans?.options?.delete_sms_history,
      sender_id_verification: customerPlans?.options?.sender_id_verification,
      send_spam_message: customerPlans?.options?.send_spam_message,
      cutting_system: customerPlans?.options?.cutting_system,
      add_previous_balance: customerPlans?.options?.add_previous_balance,
    }));
  }, [customerPlans]);

  //onchange function for plan
  const onChangeFun2 = (e, name) => {
    setPlanForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };
  //onchange function for plan checkbox
  const onChangeCheckBox = (e, name) => {
    setPlanForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value === "yes" ? "no" : "yes",
      };
    });
  };
  /* [PLAN FEATURE-Page] toggle logic in input and checkbox using state */
  const [checked1, setchecked1] = useState<boolean>();
  const [checked2, setchecked2] = useState<boolean>();
  const [checked3, setchecked3] = useState<boolean>();
  const [checked4, setchecked4] = useState<boolean>();
  const [checked5, setchecked5] = useState<boolean>();
  useEffect(() => {
    setchecked1(customerPlans?.options?.sms_max === "-1" ? true : false);
    setchecked2(customerPlans?.options?.list_max === "-1" ? true : false);
    setchecked3(customerPlans?.options?.subscriber_max === "-1" ? true : false);
    setchecked4(
      customerPlans?.options?.subscriber_per_list_max === "-1" ? true : false
    );
    setchecked5(
      customerPlans?.options?.segment_per_list_max === "-1" ? true : false
    );
  }, [customerPlans]);

  const togFun1 = () => {
    setchecked1(!checked1);
  };
  const togFun2 = () => {
    setchecked2(!checked2);
  };
  const togFun3 = () => {
    setchecked3(!checked3);
  };
  const togFun4 = () => {
    setchecked4(!checked4);
  };
  const togFun5 = () => {
    setchecked5(!checked5);
  };
  //handle disable input when tick on unlimited then it will update state of corresponding input to -1
  const handleDisableInput = (e, box, togFun) => {
    togFun();
    setPlanForm((prev) => {
      return {
        ...prev,
        [box]: e.target.checked ? "-1" : "",
      };
    });
  };

  return (
    <>
      <div className="bg-[white] shadow-md rounded-md flex gap-4 flex-col w-[100%] ">
        <form
          onSubmit={(e) => {
            handleFormSubmit(
              e,
              formDetail.formPlan.url,
              planForm,
              formDetail.formPlan._method,
              formDetail.formPlan._token
            );
            console.log(planForm);
            console.log(customerPlans);
          }}
        >
          {/*formPlan top part*/}
          <div className="mx-4 w-[100%] my-3">
            <div className="flex md:flex-row flex-col w-[100%] mt-3">
              <div className="flex flex-col md:w-[40%] w-[100%]">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  SMS sending credites<span className="text-red-600"> *</span>
                </label>
                <input
                  type="number"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[90%] md:w-[90%]"
                  value={checked1 ? "" : planForm.sms_max}
                  onChange={(e) => onChangeFun2(e, "sms_max")}
                  disabled={checked1}
                  required
                />
              </div>
              <div className="flex items-center">
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp1"
                  value=""
                  onClick={(e) => handleDisableInput(e, "sms_max", togFun1)}
                  checked={checked1}
                />
                <label
                  htmlFor="inrounded-md p1"
                  className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-[100%] mt-3">
              <div className="flex flex-col md:w-[40%] w-[100%]">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Max contact list
                </label>
                <input
                  type="number"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[90%] md:w-[90%]"
                  disabled={checked2}
                  value={checked2 ? "" : planForm.list_max}
                  onChange={(e) => onChangeFun2(e, "list_max")}
                />
              </div>
              <div className="flex items-center">
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  onClick={(e) => handleDisableInput(e, "list_max", togFun2)}
                  checked={checked2}
                />
                <label
                  htmlFor="inp2"
                  className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-[100%] mt-3">
              <div className="flex flex-col md:w-[40%] w-[100%]">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Max contacts
                </label>
                <input
                  type="number"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[90%] md:w-[90%]"
                  disabled={checked3}
                  value={checked3 ? "" : planForm.subscriber_max}
                  onChange={(e) => onChangeFun2(e, "subscriber_max")}
                />
              </div>
              <div className="flex items-center">
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp3"
                  value=""
                  onClick={(e) =>
                    handleDisableInput(e, "subscriber_max", togFun3)
                  }
                  checked={checked3}
                />
                <label
                  htmlFor="inp3"
                  className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-[100%] mt-3">
              <div className="flex flex-col md:w-[40%] w-[100%]">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Max contact per list
                </label>
                <input
                  type="number"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[90%] md:w-[90%]"
                  disabled={checked4}
                  value={checked4 ? "" : planForm.subscriber_per_list_max}
                  onChange={(e) => onChangeFun2(e, "subscriber_per_list_max")}
                />
              </div>
              <div className="flex items-center">
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp"
                  value=""
                  onClick={(e) =>
                    handleDisableInput(e, "subscriber_per_list_max", togFun4)
                  }
                  checked={checked4}
                />
                <label
                  htmlFor="inp4"
                  className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
                >
                  Unlimited
                </label>
              </div>
            </div>
            <div className="flex md:flex-row flex-col w-[100%] mt-3">
              <div className="flex flex-col md:w-[40%] w-[100%]">
                <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                  Max segments or custom fields per list
                </label>
                <input
                  type="number"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[90%] md:w-[90%]"
                  value={checked5 ? "" : planForm.segment_per_list_max}
                  onChange={(e) => onChangeFun2(e, "segment_per_list_max")}
                  disabled={checked5}
                />
              </div>
              <div className="flex items-center">
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp6"
                  value=""
                  onClick={(e) =>
                    handleDisableInput(e, "segment_per_list_max", togFun5)
                  }
                  checked={checked5}
                />
                <label
                  htmlFor="inrounded-md p6"
                  className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
                >
                  Unlimited
                </label>
              </div>
            </div>
          </div>

          {/*formPlan bottom part*/}
          <div className="mx-4 flex flex-col gap-3">
            <div className="flex items-center">
              {planForm?.list_import === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.list_import}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "list_import")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.list_import}
                  onChange={(e) => onChangeCheckBox(e, "list_import")}
                />
              )}

              <label
                htmlFor="inp1"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can import contact list
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.list_export === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.list_export}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "list_export")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.list_export}
                  onChange={(e) => onChangeCheckBox(e, "list_export")}
                />
              )}
              <label
                htmlFor="inp2"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can export contact list
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.api_access === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.api_access}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "api_access")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.api_access}
                  onChange={(e) => onChangeCheckBox(e, "api_access")}
                />
              )}
              <label
                htmlFor="inp3"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can use API
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.create_sending_server === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.create_sending_server}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "create_sending_server")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.create_sending_server}
                  onChange={(e) => onChangeCheckBox(e, "create_sending_server")}
                />
              )}
              <label
                htmlFor="inrounded-md p4"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can create own sending server
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.create_sub_account === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.create_sub_account}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "create_sub_account")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.create_sub_account}
                  onChange={(e) => onChangeCheckBox(e, "create_sub_account")}
                />
              )}
              <label
                htmlFor="inp5"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can create sub accounts
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.delete_sms_history === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.delete_sms_history}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "delete_sms_history")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.delete_sms_history}
                  onChange={(e) => onChangeCheckBox(e, "delete_sms_history")}
                />
              )}
              <label
                htmlFor="inp6"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can delete sms history
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.sender_id_verification === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.sender_id_verification}
                  checked
                  onChange={(e) =>
                    onChangeCheckBox(e, "sender_id_verification")
                  }
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.sender_id_verification}
                  onChange={(e) =>
                    onChangeCheckBox(e, "sender_id_verification")
                  }
                />
              )}
              <label
                htmlFor="inp7"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Need sending ID verification
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.send_spam_message === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.send_spam_message}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "send_spam_message")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.send_spam_message}
                  onChange={(e) => onChangeCheckBox(e, "send_spam_message")}
                />
              )}
              <label
                htmlFor="inrounded-md p8"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Customer can send spam message
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.cutting_system === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.cutting_system}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "cutting_system")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.cutting_system}
                  onChange={(e) => onChangeCheckBox(e, "cutting_system")}
                />
              )}
              <label
                htmlFor="inrounded-md p9"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Cutting system available
              </label>
            </div>
            <div className="flex items-center">
              {planForm?.add_previous_balance === "yes" ? (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.add_previous_balance}
                  checked
                  onChange={(e) => onChangeCheckBox(e, "add_previous_balance")}
                />
              ) : (
                <input
                  className="mx-1"
                  type="checkbox"
                  id="inp2"
                  value={planForm?.add_previous_balance}
                  onChange={(e) => onChangeCheckBox(e, "add_previous_balance")}
                />
              )}
              <label
                htmlFor="inrounded-md p10"
                className="text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]"
              >
                Add previous sms units on next subscription
              </label>
            </div>

            <div className="mt-4 mb-6 ">
              <button
                type="submit"
                className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
                onClick={() => console.log(planForm)}
              >
                <TfiSave className="mr-1" />
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PlanFeatures;
