import {useState, useEffect} from 'react'
import importdata from "../assets/importdata.png"
import bulkaction from "../assets/bulkaction.png"
import addnew from "../assets/addnew.png"
import search from "../assets/search.png"
import DataTable from 'react-data-table-component';
import { NavLink } from 'react-router-dom'
import { Switch } from 'antd';
import FormSkeleton from './FormSkeleton'
import {  FiEdit } from 'react-icons/fi'
import { showFailedToastAlert } from '../utils/toastAlerts'
import axios from 'axios'
import { getToken } from '../utils/tokentUtils'
import DialogBox from './DialogBox'
import usePostQuery from '../hooks/usePostQuery'


const Plans = () => {
  
  const [searchinput, setSearchInput] = useState<any>("");

  // making post request for customer search
  let postData = JSON.stringify({
    length: 10,
    start: 0,
    orderBy: "id",
    orderDir: "DESC",
    search: "",
  });

  // usign usePostQuery Hook for getting Customer list
const { queryData:plans, isLoading } = usePostQuery("/admin/plans/search","/admin/plans/search",postData)
  console.log(plans)

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const columns: any = [
    {
      name: "Name",
      selector: (row) => (
        <div className="">
          <p className="mt-[14px]">{row.plan_name}</p>{" "}
          <p className="text-[10px] text-[#F1602B] mt-[10px] mb-[13px]">
            {row.subscriber_count} Subscribers
          </p>
        </div>
      ),
    },
    {
      name: "Price",
      selector: (row) => (
        <div className=" ">
          <p className="text-[#5F6368] mt-[14px]">{row.price}</p>{" "}
          <p className="text-[#5F6368]/[0.7] mt-[10px] mb-[13px]">day</p>
        </div>
      ),
    },
    {
      name: "Sending Credit",
      selector: (row) => (
        <div className=" ">
          <p className="text-[#5F6368] mt-[14px]">{row.sending_credit}</p>{" "}
          <p className="text-[#5F6368]/[0.7] mt-[10px] mb-[13px]">
            Sending Credit
          </p>
        </div>
      ),
      // cell: row => <> <label className="switch">
      //     <input type="checkbox" checked />
      //     <span className="slider round"></span>
      // </label></>
    },
    {
      name: "Status",
      selector: (row) => <Switch defaultChecked onChange={onChange} />,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-4 ">
          {/* {showModal && <DialogBox open={showModal} title='delete' />} */}
          <DialogBox title="delete" deleteUrl={`/admin/custome/${row.id}`}/>
          <DialogBox title="copy" deleteUrl={`/admin/custome/${row.id}`}/>
          <NavLink to={`/admin/plans/${row.uid}/show`}>
            <FiEdit className="text-[#7367F0] cursor-pointer" size={20} />
          </NavLink>
          {/* <Modal/> */}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white">
      <div className="mt-[28px] pt-[23px] pb-[12px] items-center flex flex-col md:flex-row gap-[20px] md:gap-0 justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Plans
          </h2>
        </div>
        <div className="flex  gap-[15px]">
          <div className="flex items-center bg-[#2AA3001A] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[#2AA300] text-[12px] leading-[14.06px]">
              Export Data
            </p>{" "}
            <img src={importdata} alt="" />
          </div>
          <div className="flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
              Bulk Action
            </p>
            <img src={bulkaction} alt="" />
          </div>
          <NavLink to="/admin/plans/new_plan">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">Add New</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {isLoading ? (
        <FormSkeleton
          titles={["Name", "Prive", "Sending Credit", "Status", "Action"]}
        />
      ) : (
        <DataTable
          columns={columns}
          data={plans.data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      {/* <DialogBox/> */}
    </div>
  );
};

export default Plans
