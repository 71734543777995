import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { getToken } from "../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../hooks/useGetQuery";
import Currencies from "./Currencies";
import usePostQuery from "../hooks/usePostQuery";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "../utils/urls";

const EditSenderId = () => {
  const token = getToken();
  const { senderid } = useParams();
  const [priceError, setPriceError] = useState<any>("");

  //function for get the data for single senderid
  const { getQueryData: senderIdData } = useGetQuery(
    `/admin/senderid/${senderid}/show`,
    `/admin/senderid/${senderid}/show`
  );

  // state of senderid
  const [senderForm, setSenderForm] = useState<any>({
    _method:"PUT",
    _token :"wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
    sender_id: "",
    status: "",
    price: "",
    billing_cycle: "",
    currency_id: "",
    user_id: "",
    frequency_amount: "",
    frequency_unit: "",
  });

  useEffect(() => {
    setSenderForm((prevsenderForm) => ({
      ...prevsenderForm,
      sender_id: senderIdData?.senderid?.sender_id,
      price: senderIdData?.senderid?.price,
      status: senderIdData?.senderid?.status,
      billing_cycle: senderIdData?.senderid?.billing_cycle,
      currency_id: senderIdData?.currencies[0].id,
      user_id: senderIdData?.customers[0]?.id,
      frequency_amount: senderIdData?.senderid?.frequency_amount,
      frequency_unit: senderIdData?.senderid?.frequency_unit,
    }));
  }, [senderIdData]);

  console.log(senderIdData,'senderIdData')



  //// Updates the senderForm state when an input field value changes
  const onChangeSelectBox = (e, name) => {
    setSenderForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };


  const onChangeFun = (e, name) => {
    const inputValue = e.target.value.trim();

    if (name === "price") {
    }
    if (inputValue.length > 12) {
      setPriceError(
        "Invalid price length. Price should have a maximum length of 12."
      );
    } else {
      setPriceError("");
    }

    setSenderForm((prev) => {
      return {
        ...prev,
        [name]: inputValue,
      };
    });
  };



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      const data = JSON.stringify(senderForm); //Converts senderForm to a JSON string
      // Configuration object for the HTTP request
      let config: AxiosRequestConfig = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/senderid/${senderid}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data, // Includes the JSON data in the request body
      };

      const response = await axios.request(config); // Sends the HTTP request using axios

      showSuccessfulToastAlert(response?.data.message); // Shows a success toast alert with the response message
    } catch (error) {
      showFailedToastAlert(error); // Shows a failed toast alert with the error
    }
  };


  

  console.log(senderIdData?.senderid?.price);

  return (
    <>
      <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          Update Sender ID
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Sender ID
          </span>{" "}
          is what appears on people's phones to show who the SMS is from. It can
          be your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Brand Name
          </span>{" "}
          or{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Comapany Name
          </span>
          . Set{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Price
          </span>{" "}
          value{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            0
          </span>{" "}
          for free of cost sender id on SMSVENDOR
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Sender ID
              <span className="text-red-600"> *</span>
            </label>
            <input
              value={senderForm?.sender_id}
              onChange={(e) => onChangeFun(e, "sender_id")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>
          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select
              value={senderForm?.status}
              onChange={(e) => onChangeSelectBox(e, "status")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Active">
                Active
              </option>
              <option className="text-gray-700" value="payment Required">
                Payment Required
              </option>
              <option className="text-gray-700" value="Block">
                Block
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
              value={senderForm?.price}
              onChange={(e) => onChangeFun(e, "price")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
              required
            />
            <span className="form-label-size text-red-600" >{priceError}</span>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              
            >
              Billing cycle
              <span className="text-red-600"> *</span>
            </label>
            <select
              value={senderForm?.billing_cycle}
              onChange={(e) => onChangeSelectBox(e, "billing_cycle")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="daily">
                Daily
              </option>
              <option className="text-gray-700" value="monthly">
                Monthly
              </option>
              <option className="text-gray-700" value="yearly">
                Yearly
              </option>
              <option className="text-gray-700" value="custom">
                Custom
              </option>
            </select>
          </div>
          {senderForm?.billing_cycle === "custom" && (
            <div className="flex gap-2 ">
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  htmlFor="frequency_amount"
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                >
                  Frequency Amount
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  required
                  id="frequency_amount"
                  name="frequency_amount"
                  type="text"
                  value={senderForm?.frequency_amount}
                  onChange={(e)=>onChangeFun(e,"frequency_amount")}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                />
              </div>
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  htmlFor="frequency_unit"
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                >
                  Frequency Unit
                  <span className="text-red-600"> *</span>
                </label>
                <select
                  id="frequency_unit"
                  name="frequency_unit"
                  value={senderForm?.frequency_unit}
                  onChange={(e)=>onChangeSelectBox(e,'frequency_unit')}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
                >
                  <option className="text-gray-700" value="day">
                    Day
                  </option>
                  <option className="text-gray-700" value="week">
                    Week
                  </option>
                  <option className="text-gray-700" value="month">
                    Month
                  </option>
                  <option className="text-gray-700" value="year">
                    Year
                  </option>
                </select>
              </div>
            </div>
          )}

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select
              onChange={(e) => onChangeSelectBox(e, "user_id")}
              value={senderForm.user_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              {/* <option className="text-gray-700" value="option1">
                {senderForm?.select_customer}
              </option> */}
              {senderIdData?.customers?.map((val) => (
                <option className="text-gray-700" value={val?.id}>
                  {val?.first_name}({val?.last_name})
                </option>
              ))}
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select
              onChange={(e) => onChangeSelectBox(e, "currency_id")}
              value={senderForm.currency}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              {/* <option className="text-gray-700" value="option1">
                Euro(EUR)
              </option> */}
              {/* <option className="text-gray-700" value="option2">
                NPR(NPR)
              </option> */}
              {senderIdData?.currencies?.map((val) => (
                <option className="text-gray-700" value={val.id}>
                  {val.name}({val.code})
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button onClick={handleSubmit} className=" accent-btn ">
              <BiSave />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSenderId;
