import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiBaseUrl } from '../utils/urls';
import axios from 'axios';
import { getToken } from '../utils/tokentUtils';
import { showFailedToastAlert, showSuccessfulToastAlert } from '../utils/toastAlerts';

const token = getToken();

var key= "";
// this is callback function for useMuation custom hook.
const postData =async ({url,Data,validationKey})=>{
  key=validationKey
  
  console.log(Data)
  try {
     return await axios.post(`${apiBaseUrl}${url}`, Data ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res)=>res.data.status==="error"?showFailedToastAlert("error adding unit") :showSuccessfulToastAlert("Successfull")).catch((error) => {
        console.error('Error posting data:', error);
        // Handle error
      });
    } catch (error) {
      console.log("error from usePostMutation")
    }
  
  }

// this is custome hook that return useMutation function which will then return postData() function 
// at the place where usePostMutation will be used. And we can pass the url,data parameter inside postData function.
const usePostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(postData,{
    onMutate: async (newData) =>{
      showSuccessfulToastAlert("Please Wait Your Response is being Handled")
      await queryClient.cancelQueries(key)
      queryClient.setQueriesData(key,(oldQueryData)=>{
        return {
          ...oldQueryData,
          data:{
            ...oldQueryData,
            ...newData,
          }
        }
      })
      const previousdata = queryClient.getQueriesData(key)
      return {
        previousdata,
      }
    },
    onError:(_error,_hero,context)=>{
     showFailedToastAlert("Error while making request")
      queryClient.setQueriesData(key,context.previousdata)
    },
    onSettled:()=>{
      queryClient.invalidateQueries(key)
    },
  })
}

export default usePostMutation