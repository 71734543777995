import React, { useState } from "react";
import { Select } from "antd";

const SelectComponent = (props: any) => {
  const { options } = props;
  const [userCredentials, setUserCredentials] = useState({});
  const handleSelectChange = (selectedOption: any) => {
    setUserCredentials({ ...userCredentials, senderId: selectedOption });
  };
  return (
    <div>
      <Select
        defaultValue={options[0].value}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
        className="mt-[8px]"
        options={options}
      />
    </div>
  );
};

export default SelectComponent;
