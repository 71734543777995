import axios, { AxiosRequestConfig } from "axios";
import React, { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";

function SenderIdCreatePlan() {
  const token = getToken()
  // state variables
  const [createPlanForm, setCreatePlanForm] = useState<any>({
    _token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
    price: "",
    billing_cycle: "",
    frequency_amount: "",
    frequency_unit: "",
    currency_id: "",
  });

  useEffect(() => {
    setCreatePlanForm((prev) => ({
      ...prev,
      price : 0,
      billing_cycle: "daily",
      currency_id: "2",
    }));
  }, []);



  // Updates the createPlanForm state when an input field value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCreatePlanForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the createPlanForm state when an select element field value changes
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCreatePlanForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // throws error while submitting
//  message: 'No query results for model [App\\Models\\Senderid] store-plan'
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      const data = JSON.stringify(createPlanForm); //Converts senderForm to a JSON string
      // Configuration object for the HTTP request
      let config: AxiosRequestConfig = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/senderid/store-plan`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data, // Includes the JSON data in the request body
      };

      const response = await axios.request(config); // Sends the HTTP request using axios
      console.log(response,'rr')
      showSuccessfulToastAlert(response?.data.message); // Shows a success toast alert with the response message
    } catch (error) {
      showFailedToastAlert(error); // Shows a failed toast alert with the error
    }
  };




  return (
    <>
      <form onSubmit={handleSubmit} >
        <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
          <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
            Create Plan
          </p>
          <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Sender ID Plan{" "}
            </span>{" "}
            is what appears on customers' portal when they want to send a{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              custom Sender ID{" "}
            </span>{" "}
            request. You can add{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              multiple plans{" "}
            </span>{" "}
            for custom sender ID requests. Set{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Price{" "}
            </span>{" "}
            value{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              0{" "}
            </span>{" "}
            for free of cost custom sender id on SMSVENDOR
          </p>
          
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Price
                <span className="text-red-600"> *</span>
              </label>
              <input
                required
                id="price"
                name="price"
                value={createPlanForm?.price}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                type="number"
              />
            </div>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                htmlFor="billing_cycle"
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              >
                Billing cycle
                <span className="text-red-600"> *</span>
              </label>
              <select
                id="billing_cycle"
                name="billing_cycle"
                value={createPlanForm?.billing_cycle}
                onChange={handleSelectChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
                required
              >
                <option className="text-gray-700" value="daily">
                  Daily
                </option>
                <option className="text-gray-700" value="monthly">
                  Monthly
                </option>
                <option className="text-gray-700" value="yearly">
                  Yearly
                </option>
                <option className="text-gray-700" value="custom">
                  Custom
                </option>
              </select>
            </div>
            {createPlanForm?.billing_cycle === "custom" && (
              <div className="flex gap-2 ">
                <div className=" flex gap-2 mb-6 flex-col">
                  <label
                    htmlFor="frequency_amount"
                    className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                  >
                    Frequency Amount
                    <span className="text-red-600"> *</span>
                  </label>
                  <input
                    required
                    id="frequency_amount"
                    name="frequency_amount"
                    type="text"
                    value={createPlanForm?.frequency_amount}
                    onChange={handleInputChange}
                    className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                  />
                </div>
                <div className=" flex gap-2 mb-6 flex-col">
                  <label
                    htmlFor="frequency_unit"
                    className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                  >
                    Frequency Unit
                    <span className="text-red-600"> *</span>
                  </label>
                  <select
                    id="frequency_unit"
                    name="frequency_unit"
                    value={createPlanForm?.frequency_unit}
                    onChange={handleSelectChange}
                    className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
                  >
                    <option className="text-gray-700" value="day">
                      Day
                    </option>
                    <option className="text-gray-700" value="week">
                      Week
                    </option>
                    <option className="text-gray-700" value="month">
                      Month
                    </option>
                    <option className="text-gray-700" value="year">
                      Year
                    </option>
                  </select>
                </div>
              </div>
            )}

            <div className=" flex gap-2 mb-6 flex-col">
              <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
                Currency
                <span className="text-red-600"> *</span>
              </label>
              <select
                name="currency_id"
                value={createPlanForm?.currency_id}
                onChange={handleSelectChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
                required
              >
                <option value={"2"} className="text-gray-700">
                  EURO (EUR)
                </option>
                <option value={"12"} className="text-gray-700">
                  NPR (NPR)
                </option>
              </select>
            </div>

            <div className="flex justify-end">
              <button className=" accent-btn ">
                <BiSave />
                Update
              </button>
            </div>
          
        </div>
      </form>
    </>
  );
}

export default SenderIdCreatePlan;
