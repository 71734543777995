import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";

export default function usePostQuery(key, url, postData) {
  const token = getToken();

  const fetchPostData = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}${url}`, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data.data;
    } catch (error:any) {
      throw new Error(error.response.data.message);
    }
  };

  const { data: queryData, isError, isLoading } = useQuery([key], fetchPostData);

  return { queryData, isLoading, isError };
}
