import {useState} from "react";
import { BiSave } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { getToken } from "../utils/tokentUtils";

const NewTemplateTag = () => {

     const token = getToken();


   //state for  adding new numbers
  const [tagForm, setTagForm] = useState({
    name: "",
    type: "",
    required: "",
  });

    //onchange function for input
  const onChangeFun = (e, name) => {
    setTagForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };


  //onchange function for selectbox
  const onChangeSelectBox = (e, name) => {
    setTagForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  console.log(tagForm);






  return (
    <>
      
      <div className="w-[50%] my-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          New Template Tag
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          SMSVENDOR provides certain{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            template variables or tags
          </span>{" "}
          that you can use to{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            personalize message
          </span>{" "}
          content. The most commonly used variables are personalization
          variables, which allow you to dynamically include information about
          your recipients. For example, their name, email address, or custom
          properties you've stored about the person. Like, Hi{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            first_name last_name
          </span>
          , your email is{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            email
          </span>{" "}
          to Hi{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            jhon Doe
          </span>
          , your email is{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            jhone.doe@gmail.com
          </span>
          . Your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            customers
          </span>{" "}
          will see these values when they will store{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            contacts
          </span>{" "}
          on their{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            contact groups
          </span>
          . Here,{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Name
          </span>{" "}
          field is for
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            tag name
          </span>{" "}
          like{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            first_name
          </span>
          . Second,{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Type
          </span>{" "}
          field is for attribute specifies the type of{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            input
          </span>{" "}
          element to display. Finally,{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Required
          </span>
          field is for the Tag field is{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            mandatory or not
          </span>
          .
          <div className="flex  flex-wrap" >
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              first_name
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              last_name
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              email
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              username
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              company
            </span>

            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              address
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              birth_date
            </span>
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              anniversary_date
            </span>
            <span>tags already available. Don't insert these tags.</span> 
          </div>
          
          
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Name
              <span className="text-red-600"> *</span>
            </label>
            <input
                value={tagForm?.name}
                onChange={(e) => onChangeFun(e, "name")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Type
              <span className="text-red-600"> *</span>
            </label>

            <select
                value={tagForm?.type}
                onChange={(e) => onChangeSelectBox(e, "type")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="text">
                text
              </option>
              <option className="text-gray-700" value="email">
                email
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Required
              <span className="text-red-600"> *</span>
            </label>

            <select
                value={tagForm?.required}
                onChange={(e) => onChangeSelectBox(e, "required")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="optional">
                Optional
              </option>
              <option className="text-gray-700" value="required">
                Required
              </option>
            </select>
          </div>

          <div className="flex  gap-2 justify-end">
            <button className=" accent-btn ">
              <BiSave />
              Save
            </button>
            
          </div>
        </form>
      </div>
    </>
  );
};

export default NewTemplateTag;
