import React,{useState} from 'react'
import create from "../assets/create.png"
import { Select } from 'antd';

const UpdateCurrencies = () => {

    const [FormData,setFormData] = useState({
        name:"",
        code:"",
        format:""
    })

    const handleChange = (e) => {
       const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const submitForm = ()=>{
        console.log("form submitted.")
    }

    const resetForm = ()=>{
        setFormData({
            name: "",
            code: "",
            format: ""
          });
    }

    return (
        <div className='bg-white pb-[110px] rounded-[3px]'>
            <div className='mt-[17px] pb-[23px] border-b-[1px] border-[#D9D9D9]'>
                <h1 className='text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[26px] pl-[18.74px]'>UPDATE CURRENCIES</h1>
            </div>
            <form action="" className='pt-[24px] pl-[19px] max- w-[395px]'>
                <div className='py-3'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>NAME <span className='text-[#ec773c]'>*</span></h3>
                    <input className='border active:border-[#ec773c] rounded-[8px] w-full h-[38px] outline-none pl-[14px] my-[8px]' placeholder='Required' onChange={handleChange} name="name" value={FormData.name} />
                </div>
                <div className='py-3'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>CODE <span className='text-[#ec773c]'>*</span></h3>
                    <input className='border border-[#D9D9D9] rounded-[8px] w-full h-[38px] outline-none pl-[14px] my-[8px]' placeholder='Required' onChange={handleChange} name="code" value={FormData.code} />
                </div>
                <div className='py-3'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>FORMAT <span className='text-[#ec773c]'>*</span></h3>
                    <input className='border border-[#D9D9D9] rounded-[8px] w-full h-[38px] outline-none pl-[14px] my-[8px]' placeholder='$(PRICE) OR (PRICE)$' onChange={handleChange} name="format" value={FormData.format} />
                </div>
                
                <div className='flex mt-[30px] '>
                <div className='bg-[#ec773c] text-white w-[102px] h-[40px] flex  items-center justify-center gap-[8px]  float-right rounded-[3px] cursor-pointer mr-2'>
                   
                    <p onClick={submitForm} className='text-[16px]  font-medium'>SAVE</p>
                </div>
                <div className='bg-[#eacfc0] text-[#f37435] w-[102px] h-[40px] flex  items-center justify-center gap-[8px] float-right rounded-[3px] cursor-pointer mx-2'>
                    
                    <p onClick={resetForm} className='text-[16px]  font-medium'>RESET</p>
                </div>
                </div>
            </form>
        </div>
    )
}

export default UpdateCurrencies