import React from "react";
import { NavLink } from "react-router-dom";
import RegisterNav from "./RegisterNav";
import SelectComponent from "./SelectComponent";

const Register = (props: any) => {
  const { setRegister } = props;
  return (
    <div className="  flex items-center justify-center flex-col px-[166px] 2xl:px-[186px]  ">
      <div className="w-full">
        {/* <h2 className="text-[#0063A5] text-[22px] leading-[28.13px] font-normal text-center">
          Register
        </h2> */}
        <div className="flex gap-5 mb-3">
          <RegisterNav
            data={{ title: "Account", desc: "EnterCredentials", active: true }}
          />
          <RegisterNav
            data={{
              title: "Personal",
              desc: "Personal Information",
              active: false,
            }}
          />
          <RegisterNav
            data={{ title: "Billing", desc: "Payment Details", active: false }}
          />
        </div>
        <hr />
        <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
          Account Information
        </p>
        <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
          Fill the below form to create a new account
        </p>
        <form action="" className="w-full">
          {/* <input type="hidden" name="_token" value={csrfToken} /> */}
          <div className="w-full mt-[31px]">
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
              Email
            </p>
            <input
              type="email"
              placeholder="Email address"
              name="email"
              className="w-full outline-none border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              //   onChange={handleChange}
            />
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                  Password
                </p>
              </div>
              <input
                type="password"
                placeholder="password"
                name="password"
                className="w-full outline-none border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                //   onChange={handleChange}
              />
            </div>
            <div className="w-1/2">
              <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                  Confirm Password
                </p>
              </div>
              <input
                type="password"
                placeholder="Confirm Password"
                name="password"
                className="w-full outline-none border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                //   onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                  Timezone
                </p>
              </div>
              <SelectComponent options={[{ values: "" }]} />
            </div>
            <div className="w-1/2">
              <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                  Language
                </p>
              </div>
              <SelectComponent options={[{ values: "" }]} />
            </div>
          </div>
          <div className="flex items-center gap-[8px] mt-[24px]">
            <input
              type="checkbox"
              id=""
              className="w-[16px] h-[16px] cursor-pointer border border-[#808080] outline-none"
              //   onChange={(e) =>
              //     setLogin({ ...login, remember: e?.target?.checked })
              //   }
            />
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
              Remember Me
            </p>
          </div>
          <div className="flex gap-5 justify-between">
            <NavLink to="/">
              <div className="w-[200px]">
                <button
                  type="submit"
                  className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
                  onClick={() => setRegister(false)}
                >
                  back
                </button>
              </div>
            </NavLink>
            <div className="w-[200px]">
              <button
                type="submit"
                className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
