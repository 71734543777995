import { useToken } from "antd/es/theme/internal";
import axios from "axios";
import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";

const AuthenticationSection = () => {
  const token = useToken();

  // data for authentication-form
  const SocialMediaLogin = [
    {
      name: "FACEBOOK LOGIN",
      title: "Login with facebook",
      uname: "login_with_facebook",
    },
    {
      name: "TWITTER LOGIN",
      title: "Login with Twitter",
      uname: "login_with_twitter",
    },
    {
      name: "GOOGLE LOGIN",
      title: "Login with Google",
      uname: "login_with_google",
    },
    {
      name: "GITHUB LOGIN",
      title: "Login with Github",
      uname: "login_with_github",
    },
  ];

  //state for authentications
  const [authentication, setAuthentication] = useState<any>({
    client_registration: "",
    registration_verification: "",
    captcha_in_login: "1",
    captcha_in_client_registration: "1",
    captcha_site_key: "",
    captcha_secret_key: "",
    two_factor: "",
    two_factor_send_by: "",
    login_with_facebook: "",
    facebook_client_id: "",
    facebook_client_secret: "",
    login_with_twitter: "",
    twitter_client_id: "",
    twitter_client_secret: "",
    login_with_google: "",
    google_client_id: "",
    google_client_secret: "",
    login_with_github: "",
    github_client_id: "",
    github_client_secret: "",
    _token: "vfcBtwNED453WPD5m0SBvDAdBOAORG39V65iMUGs",
  });

  // handle onchange function for inputs
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setAuthentication((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  //
  useEffect(() => {
    if (
      authentication.captcha_in_login === "0" ||
      authentication.captcha_in_client_registration === "0"
    ) {
      setAuthentication((prevState) => {
        return {
          ...prevState,
          captcha_secret_key: "",
          captcha_site_key: "",
        };
      });
    }
  }, [
    authentication.captcha_in_login,
    authentication.captcha_in_client_registration,
  ]);

  // console.log(authentication);

  // set intial data when component is mounted
  // useEffect(() => {
  //     setAuthentication((prevState)=>{
  //       return{
  //         ...prevState,
  //         login_with_facebook:0,
  //         login_with_twitter:0,
  //         login_with_google:0,
  //         login_with_github:0,

  //       }
  //     })
  // }, [])

  // handling authentication-form-submit
  // const handleAutheticationFormSubmit =async(e) =>{
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       `https://smsvendor.com/api/v1/admin/settings/authentication`,
  //       {...authentication},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     console.log(response.data)
  //     showSuccessfulToastAlert(response?.data.message);
  //   } catch (error) {
  //     showFailedToastAlert(error);
  //   }

  // }

  const handleAutheticationFormSubmit = (e) => {
    e.preventDefault();
    console.log(authentication, "submission");
  };

  return (
    <>
      <div className="w-[100%] md:w-[60%] mt-[2rem]  px-4">
        <form action="">
          <div className="">
            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size  mb-[0.2875] "
                htmlFor=""
              >
                Allow client registration
                <span className="text-red-600"> *</span>
              </label>
              <select
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="client_registration"
                onChange={onChangeFun}
                value={authentication.client_registration}
              >
                <option value="1">yes</option>
                <option value="0">no</option>
              </select>
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Verification after client registration
                <span className="text-red-600"> *</span>
              </label>
              <select
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="registration_verification"
                onChange={onChangeFun}
                value={authentication.registration_verification}
              >
                <option value="1">yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Two factor authentication in login
                <span className="text-red-600"> *</span>
              </label>
              <select
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="two_factor"
                onChange={onChangeFun}
                value={authentication.two_factor}
              >
                <option value="1">yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className="flex flex-col gap-2 mb-6">
              <div className="flex items-center  justify-evenly  ">
                <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                <label className="flex-1 text-center w-full text-[#7367F0] form-label-size mb-[0.2875] ">
                  RECAPTCHA INFORMATION
                </label>
                <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
              </div>
              <div>
                <p className="text-justify leading-[1.5rem] text-[var(--accent-gray)] text-[13.125px] ">
                  <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                    reCAPTCHA
                  </span>
                  is a free service that protects your site from spam and abuse,
                  developed by{" "}
                  <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                    Google
                  </span>
                  . For this reason you will need a Google Account first. If you
                  don’t have, simply{" "}
                  <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                    Create a new
                  </span>{" "}
                  <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                    Google account
                  </span>
                  . Once you have an account with Google, log into the{" "}
                  <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                    reCaptcha Admin
                  </span>{" "}
                  page and setup your application install URL. For more details
                  please check{" "}
                  <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                    Ultimate SMS Blog
                  </span>
                </p>
              </div>
            </div>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                reCaptcha site key
              </label>
              <input
                name="captcha_site_key"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                value={
                  authentication.captcha_in_login === "1"
                    ? authentication.captcha_site_key
                    : ""
                }
                required={authentication.captcha_in_login}
              />
              {authentication.captcha_in_login === "1" ||
              authentication.captcha_in_client_registration === "1" ? (
                <span className="text-red-600 form-label-size ">
                  * required
                </span>
              ) : null}
            </div>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                reCaptcha secret key
              </label>
              <input
                name="captcha_secret_key"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                // value={authentication.captcha_secret_key}
                value={
                  authentication.captcha_in_login === "1"
                    ? authentication.captcha_secret_key
                    : ""
                }
                required={authentication.captcha_in_login}
              />
              {authentication.captcha_in_login === "1" ||
              authentication.captcha_in_client_registration === "1" ? (
                <span className="text-red-600 form-label-size ">
                  * required
                </span>
              ) : null}
            </div>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Captcha in login
                <span className="text-red-600"> *</span>
              </label>

              <select
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="captcha_in_login"
                onChange={onChangeFun}
                value={authentication.captcha_in_login}
              >
                <option value="1">yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Captcha in client registration
                <span className="text-red-600"> *</span>
              </label>

              <select
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="captcha_in_client_registration"
                onChange={onChangeFun}
                value={authentication.captcha_in_client_registration}
              >
                <option value="1">yes</option>
                <option value="0">No</option>
              </select>
            </div>

            <div>
              {SocialMediaLogin.map((val, ind) => {
                return (
                  <>
                    <div key={ind} className="flex flex-col gap-2 mb-[2rem]">
                      <div className="flex items-center  justify-evenly  ">
                        <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                        <label className="flex-1 text-center w-full text-[#7367F0] text-[13.125px] mb-[0.2875] ">
                          {val?.name}
                        </label>
                        <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                      </div>

                      <label className="text-[var(--accent-gray)] form-label-size mb-[0.2875] ">
                        {val.title}
                        <span className="text-red-600"> *</span>
                      </label>
                      <select
                        className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                        name={val.uname}
                        onChange={onChangeFun}
                        value={authentication[val.uname]}
                      >
                        <option value="1">yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleAutheticationFormSubmit}
              className="hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn "
            >
              <BiSave />
              save
            </button>
          </div>
        </form>

        {/* ==================authentication-form-3 ended================= */}
      </div>
    </>
  );
};

export default AuthenticationSection;
