import React, { useState, useEffect } from "react";
import { BiBell, BiSave } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { FiClock, FiLock, FiMessageSquare } from "react-icons/fi";
import { HiOutlineDocument } from "react-icons/hi";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import GeneralSection from "./AllSettings/GeneralSection";
import SystemEmailSection from "./AllSettings/SystemEmailSection";
import NotificationsSection from "./AllSettings/NotificationsSection";

import PusherSection from "./AllSettings/PusherSection";
import CronJobsSection from "./AllSettings/CronJobsSection";
import LicenseSection from "./AllSettings/LicenseSection";
import AuthenticationSection from "./AllSettings/AuthenticationSections";

const Settings = () => {
  const [showForm, setShowForm] = useState<any>(null);

  const handleClick = (name: string) => {
    if (name === showForm) {
      setShowForm(name);
    } else {
      setShowForm(name);
    }
  };

  // ========= to show general-form-1 first when component is mounted========
  useEffect(() => {
    setShowForm("general");
  }, []);

  return (
    <>
      <div className="bg-white my-[1rem]  p-[8px]">
        {/* =============== buttons-for-navigating-forms start==================== */}
        <div className="flex flex-wrap md:flex-row pt-[20px] pb-[15px] mb-2 gap-4 justify-around">
          <button
            onClick={() => handleClick("general")}
            // className=" flex form-title-size gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
            className={
              showForm === "general"
                ? "flex form-title-size  ease-in-out duration-300   gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size   ease-in-out duration-300   gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]   bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <BsGear />
            General
          </button>
          <button
            onClick={() => handleClick("system")}
            // className="flex form-title-size gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "system"
                ? "flex form-title-size  ease-in-out duration-300   gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size  ease-in-out duration-300   gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <MdOutlineMail />
            System Email
          </button>
          <button
            onClick={() => handleClick("authentication")}
            // className="flex form-title-size gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "authentication"
                ? "flex form-title-size ease-in-out duration-300  gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size ease-in-out duration-300  gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <FiLock />
            Authentication
          </button>
          <button
            onClick={() => handleClick("notification")}
            // className="flex form-title-size gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "notification"
                ? "flex form-title-size ease-in-out duration-300  gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size ease-in-out duration-300  gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]   bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <BiBell />
            Notifications
          </button>
          <button
            onClick={() => handleClick("pusher")}
            // className="flex form-title-size gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)]  text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "pusher"
                ? "flex form-title-size ease-in-out duration-300  gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size ease-in-out duration-300  gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]   bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <FiMessageSquare />
            Pusher
          </button>
          <button
            onClick={() => handleClick("cronjobs")}
            // className="flex form-title-size gap-2 items-center   border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "cronjobs"
                ? "flex form-title-size ease-in-out duration-300  gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size ease-in-out duration-300  gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]   bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <FiClock />
            Cron Jobs
          </button>
          <button
            onClick={() => handleClick("license")}
            // className="flex form-title-size gap-2 items-center   border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)] hover:border-b-2 hover:border-[var(--accent-orange)] bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]  "
            className={
              showForm === "license"
                ? "flex form-title-size ease-in-out duration-300  gap-2 items-center   text-[var(--accent-orange)]  border-b-2 border-[var(--accent-orange)]  bg-transparent  font-[400] py-[0.61rem] px-[1.2rem] "
                : "flex form-title-size ease-in-out duration-300  gap-2 items-center  border-b-2 border-transparent hover:text-[var(--accent-orange)] text-[var(--accent-gray)]   bg-transparent  font-[400] py-[0.61rem] px-[1.2rem]"
            }
          >
            <HiOutlineDocument />
            License
          </button>
        </div>
        {/* =============== buttons-for-navigating-forms end==================== */}

        {/* ================general-form-1 start================*/}
        {showForm === "general" && <GeneralSection />}

        {/* ================system-form-2 start================*/}
        {showForm === "system" && <SystemEmailSection />}

        {/* ================Authentication-form-3 start================*/}
        {showForm === "authentication" && <AuthenticationSection />}

        {/* ================Notifications-form-4 start================*/}
        {showForm === "notification" && <NotificationsSection />}

        {/* ================pusher-form-5 start================*/}
        {showForm === "pusher" && <PusherSection />}

        {/* ================cronjobs-form-6 start================*/}
        {showForm === "cronjobs" && <CronJobsSection />}

        {/* ================license-form-7 start================*/}
        {showForm === "license" && <LicenseSection />}
      </div>
    </>
  );
};

export default Settings;
