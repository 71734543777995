import React, { useState, useEffect, useCallback } from "react";
import importdata from "../assets/importdata.png";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BiCart } from "react-icons/bi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { RxGear } from "react-icons/rx";
import { apiBaseUrl } from "../utils/urls";
import { useToken } from "antd/es/theme/internal";

const PaymentGateways = () => {
  const token = getToken(); // token
  console.log(token, "tokkee");

  // To post toggle status to api
  const [active, setActive] = useState<any>(false);

  // functions to toggle-status-change
  const toggleStatusChange = async (checked: boolean, id) => {
    console.log(paymentGateways.status, "check");

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiBaseUrl}/admin/payment-gateways/${id}/active`,
      headers: {
        Authorization: "Bearer 1|HzhREUG5vJv8zHrWPF2uJIFr5g4pwigmdmV3J1Me",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        showSuccessfulToastAlert(response?.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns: any = [
    {
      name: "Name",
      selector: (row) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div>
              <p className="text-[14px] text-[#5F6368]">{row.name}</p>
            </div>
          </div>
        </>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <Switch
          className="text-[10px] bg-[var(--accent-orange)] "
          checked={row.status}
          onChange={(checked) => toggleStatusChange(checked, row.id)}
        />
        // <input
        //   onChange={() => toggleStatus(row.id)}
        //   checked={row.status}
        //   className="toggle toggle-warning text-lime-600  bg-slate-500 "
        //   type="checkbox"
        // />
        // <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        //   <input
        //     type="checkbox"

        //     name="toggle"
        //     id="toggle"
        //     className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        //   />
        //   <label
        //     htmlFor="toggle"
        //     className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        //   ></label>
        // </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-4 ">
          <NavLink
            className="flex text-[#7367F0] gap-2"
            to={`/admin/payment-gateways/${row.id}/show`}
          >
            <RxGear size={15} />
            Settings
          </NavLink>
        </div>
      ),
    },
  ];

  const [paymentGateways, setPaymentGateways] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");

  console.log(paymentGateways, "pgpgpg");

  const statuses = paymentGateways?.map((obj) => ({
    uid: obj.uid,
    status: obj.status,
  }));
  console.log(statuses, "test");

  useEffect(() => {
    try {
      const token = getToken();
      // let data = JSON.stringify({
      //   // length: 10,
      //   // start: 0,
      //   // orderBy: "id",
      //   // orderDir: "DESC",
      //   // search: searchinput,
      // });

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/payment-gateways`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setPaymentGateways(data?.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, [searchinput]);

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue: any) => {
    setTable(paginatedValue);
  };

  console.log(dataForTable, "dtft");

  // data for table
  const data = dataForTable?.map((val) => ({
    id: val.uid,
    name: val.name,
    status: val.status,
  }));

  return (
    <div className="bg-white ">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Payment Gateways
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {paymentGateways === null ? (
        <FormSkeleton titles={["NAME", "STATUS", "ACTIONS"]} />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      <HandlingPagination
        data={paymentGateways}
        getDataForTable={getDataForTable}
      />
    </div>
  );
};

export default PaymentGateways;
