import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { object } from "yup";

const NotificationsSection = () => {
  // -----------data for notifications-form----------
  const notificationData = [
    {
      title: "Enable Sender ID Request notification by Email",
      id: 0,
      checked: false,
    },
    {
      title: "Enable Sender ID Request notification by SMS",
      id: 1,
      checked: false,
    },
    {
      title: "Enable User Registration notification by Email",
      id: 2,
      checked: false,
    },
    {
      title: "Enable User Registration notification by SMS",
      id: 3,
      checked: false,
    },
    {
      title: "Enable Subscription notification by Email",
      id: 4,
      checked: false,
    },
    {
      title: "Enable Subscription notification by SMS",
      id: 5,
      checked: false,
    },
    {
      title: "Enable Keyword purchase notification by Email",
      id: 6,
      checked: false,
    },
    {
      title: "Enable Keyword purchase notification by SMS",
      id: 7,
      checked: false,
    },
    {
      title: "Enable Phone number purchase notification by Email",
      id: 8,
      checked: false,
    },
    {
      title: "Enable Phone number purchase notification by SMS",
      id: 9,
      checked: false,
    },
    {
      title: "Get notification by Email if any send fraud sms",
      id: 10,
      checked: false,
    },
    {
      title: "Get notification by SMS if any send fraud sms",
      id: 11,
      checked: false,
    },
  ];
  // ------------------------------------------------------------

  // state of Notifications
  const [notification, setNotifications] = useState({
    notification_sms_gateway: "",
    notification_sender_id: "",
    notification_phone: "",
    notification_from_name: "",
    notification_email: "",
  });

  // handles onchange function for all inputs types text
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setNotifications((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  // all checked input is stored inside new checkboxes objects
  const [checkboxes, setCheckboxes] = useState({});

  // handles checkboxes
  const handleCheck = (id) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [id]: !prevCheckboxes[id],
    }));
  };

  console.log(checkboxes,'kkkk')

  return (
    <>
      <div className="w-[100%] md:w-[60%] mt-[2rem]  px-4">
        <form action="">
          <div className="">
            <div className="flex gap-2 mb-6 flex-col">
              <label
                htmlFor="notification_sms_gateway"
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              >
                sms gateway for sms notification
                <span className="text-red-600"> *</span>
              </label>
              <select
                id="notification_sms_gateway"
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                name="notification_sms_gateway"
                onChange={onChangeFun}
                required
              >
                <option value="NS">NS</option>
                <option value="ES">ES</option>
                <option value="Supreme SMS">Supreme SMS</option>
              </select>
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor="notification_sender_id"
              >
                Sender id for sms noifications
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="notification_sender_id"
                name="notification_sender_id"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                required
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor="notification_phone"
              >
                phone number for sms notifications
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="notification_phone"
                name="notification_phone"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                required
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor="notification_from_name"
              >
                From name for email notification
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="notification_from_name"
                name="notification_from_name"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                required
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor="notification_email"
              >
                Email address for email notification
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="notification_email"
                name="notification_email"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                required
              />
            </div>

            <div>
              {notificationData.map((data, ind) => {
                return (
                  <>
                    <div key={data.id} className="flex gap-2 mb-6 ">
                      <input
                        id={data.title}
                        className="w-[15px] cursor-pointer shadow-lg text-white accent-[var(--accent-orange)] checkbox-white h-[15px]"
                        type="checkbox"
                        checked={checkboxes[data.id]}
                        onChange={handleCheck}
                      />
                      <label
                        htmlFor={data.title}
                        className="text-[var(--accent-gray)] cursor-pointer form-label-size mb-[0.2875] "
                      >
                        {data.title}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="flex justify-end">
            <button className="hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn ">
              <BiSave />
              save
            </button>
          </div>
        </form>

        {/* ==================Notifications-form-3 ended================= */}
      </div>
    </>
  );
};

export default NotificationsSection;
