import React,{useState,useEffect} from 'react'
import create from "../assets/create.png"
import { Select } from 'antd';
import { showFailedToastAlert, showSuccessfulToastAlert } from '../utils/toastAlerts';
import { apiBaseUrl } from '../utils/urls';
import axios from 'axios';
import useGetQuery from '../hooks/useGetQuery';
import TimeZonePicker from '../TimeZonePicker';
import { getToken } from '../utils/tokentUtils';
import { useNavigate, useParams } from 'react-router-dom';

const Newcustomer = () => {
  // const navigate = useNavigate();
  // const {customer} = useParams();
const token = getToken();
  //function for get the data for language
  const { getQueryData: languageData } = useGetQuery(
    `/admin/languages`,
    `/admin/languages`
  );

    const [form,setForm] = useState({
        email:"",
        password:"",
        password_confirmation:"",
        first_name:"",
        last_name:"",
        phone:"",
        timezone:"Asia/Kathmandu",
        locale:"en",
        status:true,
        // image:"(binary)"
    })

    const handleChange = (e) => {
       const { name, value } = e.target;
        setForm(prevState => ({ ...prevState, [name]: value }));
    };

      //onchange function for selectbox of new plan
  const onChangeSelectBox = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };


  /** validation logic started**/

  //Errors validation state
  const [errors, setErrors] = useState({
    password: "",
    password_confirmation: "",

  });

  //validation for create administrator
  const validateForm = () => {
    var status =0;
    // Perform validation checks
    if (form.password.length < 8) {
      setErrors((prev) => {
        return {
          ...prev,
          password: "Password must be minimum 8 character",
        };
      });
      status=1;

    } else {
      setErrors((prev) => {
        return {
          ...prev,
          password: "",
        };
      });
    }
    if (form.password === form.password_confirmation) {
      setErrors((prev) => {
        return {
          ...prev,
          password_confirmation: "",
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          password_confirmation: "Password didn't matched",
        };
      });
      status=1;

    }
    return status
  };
  useEffect(() => {
    validateForm();
  }, [form]);
  /** validation logic ended*/
//handle on submit form of create administrator
const handleFormSubmit = async (e, data) => {
  e.preventDefault();
  if (!validateForm()) {
    console.log("running unwantedly");
    const jsondata = JSON.stringify(data);

    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/customers`,
         jsondata ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
      // navigate(`/customers/${customer}/show`)
    } catch (error) {
      showFailedToastAlert(
        `Error: ${error}`
      );    }
  } else {
    showFailedToastAlert("validation errors");
  }
};

  // thsi is callback function for TimeZone component.
  const getTimeZone = (timezone) => {
    setForm((prevForm1) => ({
      ...prevForm1,
      timezone:timezone,
    }));
  };
  console.log(form.timezone);

    return (
        <div className='bg-white pb-[110px] rounded-[3px]'>
            <div className='mt-[17px] pb-[23px] border-b-[1px]  border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[26px] px-4'>New Customers</h2>
            </div>
            <form action="" className='pt-[24px] md:w-[60%] w-[100%] flex flex-col gap-3' onSubmit={(e)=>{handleFormSubmit(e,form)}}>
                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Email *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' placeholder='' onChange={handleChange} name="email" type='email' value={form.email} required/>
                </div>

                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Password *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="password" value={form.password} required/>
                    {errors?.password && (
            <span className="text-[red] text-sm">{errors?.password}</span>
          )}
                </div>

                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Confirm Password *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="password_confirmation" value={form.password_confirmation} required/>
                    {errors?.password_confirmation && (
            <span className="text-[red] text-sm">{errors?.password_confirmation}</span>
          )}
                </div>

                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>First Name *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="first_name" value={form.first_name} required/>
                </div>

                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Last_name </h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]'  placeholder='' onChange={handleChange} name="last_name" value={form.last_name}/>
                </div>

                  <div className='px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Phone *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]'  placeholder='' onChange={handleChange} name="phone" value={form.phone} required/>
                </div>

                <div className='mt-[15px] px-6'>
                                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Timezone<span className="text-red-600"> *</span>
              </label>
              <TimeZonePicker data={form.timezone} getTimeZone={getTimeZone} />
            </div>

                <div className='mt-[15px] px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Language *</h3>
                       <select
                        className='w-full mt-[9px] border-[1px] h-[40px]'
                        
                        onChange={(e)=>onChangeSelectBox(e,'locale')}
            
                    >
                       {languageData?.map((val,key)=>(
                <option value={val?.code}>{val?.name}</option>
              ))}
                    </select>
                </div>

                <div className='mt-[15px] px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Status *</h3>
                       <select
                        className='w-full mt-[9px] border-[1px] h-[40px]'
                        
                        onChange={(e)=>onChangeSelectBox(e,'status')}
                 
                    >
                              {[['Active','1'],["Inactive",'0']].map((val)=>{
                        return(
                            <option value={val[1]}>{val[0]}</option>
                        )
                       })}
                    </select>
                </div>

                <div className='mt-[15px] px-6'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal pb-2'>Image</h3>
                       <input type='file' accept="image/*" className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]' name='image'   onChange={handleChange}
                    />
                    <p className='text-[#229]'>Recommended size: Width 300px X Height 300px</p>
                </div>

          <div className='ml-[25px]'>
          <button type='submit' className=' bg-[#0092D7] text-white w-[102px] h-[40px] flex  items-center justify-center gap-[8px] mt-[30px]  rounded-[3px] cursor-pointer'>
                    <img src={create} alt="" />
                    <p  className='text-[16px]  font-normal'>Create</p>
                </button>
          </div>
            </form>
        </div>
    )
}

export default Newcustomer;