import { useState, useEffect } from "react";
import usePostMutation from "../../hooks/usePostMutation";
import { getToken } from "../../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../../hooks/useGetQuery";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import { RiAccountCircleFill, RiDeleteBinLine } from "react-icons/ri";
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineMinusSm,
  HiOutlinePlus,
  HiOutlineUpload,
} from "react-icons/hi";
import TimeZonePicker from "../../TimeZonePicker";
import { TfiSave } from "react-icons/tfi";
import { Button, Modal } from "antd";
import { BiSave } from "react-icons/bi";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { apiBaseUrl } from "../../utils/urls";

const Account = () => {
  const token = getToken();
  const { customer } = useParams();

  //function for get the data for single customer
  const { getQueryData: customerData } = useGetQuery(
    `/admin/customers/${customer}/show`,
    `/admin/customers/${customer}/show`
  );

  //state of form1
  const [form1, setForm1] = useState({
    _method: "PATCH",
    _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    timezone: "",
    locale: "",
  });

  useEffect(() => {
    setForm1((prevForm1) => ({
      ...prevForm1,
      email: customerData?.customer.email,
      first_name: customerData?.customer.first_name,
      last_name: customerData?.customer?.last_name,
      timezone: customerData?.customer?.timezone,
      locale: customerData?.customer?.locale,
    }));
  }, [customerData]);

  //onchange function to update input field of form1
  const handleForm1Change = (event) => {
    const { name, value } = event.target;
    setForm1((prevForm1) => ({
      ...prevForm1,
      [name]: value,
    }));
  };
  // thsi is callback function for TimeZone component.
  const getTimeZone = (timezone) => {
    setForm1((prevForm1) => ({
      ...prevForm1,
      timezone: timezone,
    }));
  };
  console.log(form1.timezone);

  // for showing and hiding password and confirm password.
  const [showPassword, setShowPassword] = useState<any>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(true);

  // call for usePost Mutation hook for better for making post request.
  const { mutate: addUnit } = usePostMutation();
  const { mutate: removeUnit } = usePostMutation();

  // =============== handling diaglogBox ===========
  // state variables for both modals
  const [isModalOpen, setIsModalOpen] = useState({
    modalAdd: false,
    modalRemove: false,
  });

  // state to stores user input of add_unit modal
  const [addingUnit, setAddingUnit] = useState<any>();
  // state to stores user input of remove_unit modal
  const [removingUnit, setRemovingUnit] = useState<any>();

  // onChange handler for add_unit input
  const handleAddUnitChange = (e: any) => {
    const { value } = e.target;
    setAddingUnit(value);
  };
  // onChange handler for remove_unit input
  const handleRemoveUnitChange = (e: any) => {
    const { value } = e.target;
    setRemovingUnit(value);
  };

  // handles add_unit form submission
  const handleAddUnitSubmit = (id, unit, validationKey) => {
    if (addingUnit) {
      addUnit({
        url: `/admin/customers/${id}/add-unit`,
        Data: {
          add_unit: Math.floor(unit), // convert input value if it is in decimal
          _token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
        },
        validationKey,
      });
    }
    if (!addingUnit) {
      // show alert if submitted with empty input value
      console.log("failed");
      showFailedToastAlert("Enter a unit value");
    }
    // Close the "modalAdd" window
    setIsModalOpen((prev) => ({
      ...prev,
      modalAdd: false,
    }));
    setAddingUnit(""); // reset the input value
  };

  // handles remove_unit form submission
  const handleRemoveUnitSubmit = (id, unit, validationKey) => {
    if (removingUnit) {
      removeUnit({
        url: `/admin/customers/${id}/remove-unit`,
        Data: {
          add_unit: Math.floor(unit), // convert input value if it is in decimal
          _token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
        },
        validationKey,
      });
    }
    if (!removingUnit) {
      // show alert if submitted with empty input value
      console.log("failed");
      showFailedToastAlert("Enter a unit value");
    }
    // Close the "modalRemove" window
    setIsModalOpen((prev) => ({
      ...prev,
      modalRemove: false,
    }));
    setRemovingUnit(""); // reset the input value
  };

  // handles to show both "modalAdd" and "modalRemove" modal
  const showModal = (title) => {
    if (title === "add") {
      setIsModalOpen((prev) => ({
        ...prev,
        modalAdd: !isModalOpen.modalAdd,
      }));
    } else {
      setIsModalOpen((prev) => ({
        ...prev,
        modalRemove: !isModalOpen.modalRemove,
      }));
    }
  };

  // handles cancelling both "modalAdd" and "modalRemove" modal
  const handleCancel = (title) => {
    if (title === "add") {
      setIsModalOpen((prev) => ({
        ...prev,
        modalAdd: false,
      }));
    } else {
      setIsModalOpen((prev) => ({
        ...prev,
        modalRemove: false,
      }));
    }
  };
  // ==================================================

  // hendling form1 submit.
  const handleForm1Submit = async (e) => {
    e.preventDefault();
    if (form1.password === form1.password_confirmation) {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/admin/customers/${customer}`,
          { ...form1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        showSuccessfulToastAlert(response?.data.message);
      } catch (error) {
        showFailedToastAlert(error);
      }
    } else {
      showFailedToastAlert("your Password did't match Password confirmation");
    }
  };

  return (
    <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
      <div className="top flex gap-4 items-center">
        <div>
          <RiAccountCircleFill size={150} />
        </div>
        <div>
          <p>SMS UNIT : {customerData?.customer.sms_unit} </p>
          <div className="flex gap-4 my-2 flex-wrap">
            <button className="bg-[#F08B38] text-white px-3 py-1 rounded-md flex items-center gap-1 ">
              <HiOutlineUpload />
              Upload
            </button>
            <button
              onClick={() => showModal("add")}
              className="bg-[#aff079] text-white px-3 py-1 rounded-md flex items-center gap-1 "
            >
              <HiOutlinePlus />
              AddUnit
            </button>
            <Modal
              title="Add Unit"
              open={isModalOpen.modalAdd}
              // onOk={handleOk}
              footer={null}
              centered={true}
              onCancel={() => handleCancel("add")}
            >
              <p className="text-[var(--accent-gray)] text-justify leading-[1.5rem] form-label-size mb-[1rem]">
                Please use only{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  numeric
                </span>{" "}
                number not{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  decimal
                </span>{" "}
                number. Example: Accepted:{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  1, 2, 3
                </span>{" "}
                Not Accepted:{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  0.025, 1.025, 1.00, 1.5
                </span>{" "}
              </p>
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] form-label-size font-bold mb-[0.2875] "
                  htmlFor="addingUnit"
                >
                  Add Unit
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  id="addingUnit"
                  name="addingUnit"
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                  value={addingUnit}
                  onChange={handleAddUnitChange}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    handleAddUnitSubmit(
                      customerData?.customer.uid,
                      addingUnit,
                      `/admin/customers/${customer}/show`
                    )
                  }
                  className=" hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn "
                >
                  <AiOutlinePlusSquare />
                  Add Unit
                </button>
              </div>
            </Modal>

            <button
              onClick={() => showModal("remove")}
              className="bg-[#F08B38] text-white px-3 py-1 rounded-md flex items-center gap-1 "
            >
              <HiOutlineMinusSm />
              RemoveUnit
            </button>
            <Modal
              title="Remove Unit"
              open={isModalOpen.modalRemove}
              // onOk={handleOk}
              footer={null}
              centered={true}
              onCancel={() => handleCancel("remove")}
            >
              <p className="text-[var(--accent-gray)] text-justify leading-[1.5rem] form-label-size mb-[1rem]">
                Please use only{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  numeric
                </span>{" "}
                number not{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  decimal
                </span>{" "}
                number. Example: Accepted:{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  1, 2, 3
                </span>{" "}
                Not Accepted:{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  0.025, 1.025, 1.00, 1.5
                </span>{" "}
              </p>
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] form-label-size font-bold mb-[0.2875] "
                  htmlFor="addingUnit"
                >
                  Remove Unit
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  id="removingUnit"
                  name="removingUnit"
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                  value={removingUnit}
                  onChange={handleRemoveUnitChange}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    handleRemoveUnitSubmit(
                      customerData?.customer.uid,
                      removingUnit,
                      `/admin/customers/${customer}/show`
                    )
                  }
                  className=" hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn "
                >
                  <AiOutlineMinusSquare />
                  Remove Unit
                </button>
              </div>
            </Modal>
            <button className="bg-[#EA5455] text-white px-3 py-1 rounded-md flex items-center gap-1 ">
              <RiDeleteBinLine />
              remove
            </button>
          </div>
        </div>
      </div>

      {/* -------form part 1----- */}
      <form action="" onSubmit={(e) => handleForm1Submit(e)}>
        <div className="bottom  p-2 flex flex-col md:flex-row flex-wrap">
          <div className="left md:w-[50%] w-[100%] md:pr-2">
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Email<span className="text-red-600"> *</span>
              </label>
              <input
                value={form1.email}
                type="email"
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                name="email"
                onChange={handleForm1Change}
              />
            </div>
            <div className="flex flex-col  mb-[16px]  ">
              <div className="relative  flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Password<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1.password}
                  type={showPassword ? "password" : "text"}
                  className="rounded-[0.357rem] relative text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem]"
                  name="password"
                  onChange={handleForm1Change}
                />
                {showPassword ? (
                  <HiOutlineEye
                    className="absolute cursor-pointer first-letter: right-[15px] top-[55%] "
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <HiOutlineEyeOff
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col mb-[16px]  ">
              <div className="relative flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Confirm Password<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1.password_confirmation}
                  type={showConfirmPassword ? "password" : "text"}
                  className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem] "
                  name="password_confirmation"
                  onChange={handleForm1Change}
                />
                {showConfirmPassword ? (
                  <HiOutlineEye
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <HiOutlineEyeOff
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="Right md:w-[50%] w-[100%] md:pl-2">
            <div className="right-sub flex  gap-2 mb-[16px] items-center  ">
              <div className="flex flex-col items-center w-[50%]  gap-1">
                <label className="text-[0.857rem] text-[#5e5873]  mb-[0.2857rem] self-start ">
                  First name<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1?.first_name}
                  type="text"
                  lang="ne"
                  className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                  name="first_name"
                  style={{ fontFamily: "Preeti, Arial, sans-serif" }}
                  onChange={handleForm1Change}
                />
              </div>
              <div className="flex flex-col items-center w-[50%] gap-1 text-left">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] self-start ">
                  Last name
                </label>
                <input
                  value={form1?.last_name}
                  type="text"
                  className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                  name="last_name"
                  onChange={handleForm1Change}
                />
              </div>
            </div>
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Timezone<span className="text-red-600"> *</span>
              </label>
              <TimeZonePicker data={form1.timezone} getTimeZone={getTimeZone} />
            </div>
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Language<span className="text-red-600"> *</span>
              </label>
              <select
                name="language"
                onChange={handleForm1Change}
                id=""
                className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              >
                {customerData?.languages?.map((option, index) => (
                  <option key={index} value={option?.code}>
                    {option?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
            >
              <TfiSave />
              save changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Account;
