import React, { useState, useEffect } from "react";
import importdata from "../assets/importdata.png";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BiCart } from "react-icons/bi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { RxGear } from "react-icons/rx";
import { apiBaseUrl } from "../utils/urls";

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const columns: any = [
  {
    name: "NAME",
    selector: (row) => (
      <>
        <div className="flex gap-[10px] cursor-pointer">
          {" "}
          {/* <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full">
            <img src={row.avatar} alt="" className="cursor-pointer" />
          </div> */}
          <div>
            <p className="text-[14px] text-[#5F6368]">{row.name}</p>
            <p className="text-[11px] text-[#5F6368]">{row.email}</p>
          </div>
        </div>
      </>
    ),
  },
  {
    name: "ADMINS",
    selector: (row) => row.admins,
  },
  {
    name: "STATUS",
    selector: (row) => (
      <Switch
        className="text-[10px] bg-[var(--accent-orange)] "
        checked={row.status}
        onChange={onChange}
      />
    ),
    // cell: row => <> <label className="switch">
    //     <input type="checkbox" checked />
    //     <span className="slider round"></span>
    // </label></>
  },
  {
    name: "ACTIONS",
    selector: (row) => (
      <div className="flex gap-4 ">
        <NavLink to="/admin/customers/edit">
          <AiOutlineDelete
            className="text-[#EA5455] cursor-pointer "
            size={25}
          />
        </NavLink>
        <NavLink to={`/admin/roles/${row.id}/show`}>
          <FiEdit className="text-[#7367F0] cursor-pointer" size={20} />
        </NavLink>
      </div>
    ),
  },
];

const Roles = () => {
  const [roles, setRoles] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");
  console.log(roles);
  console.log(searchinput);

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/roles/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setRoles(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val) => ({
    id: val.uid,
    name: val.name,
    admins: val.admins,
    status: val.status,
    // current: val.subscription,
  }));

  return (
    <div className="bg-white ">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Admin Roles
          </h2>
        </div>

        {/* <div className="flex gap-[15px] flex-wrap">
          <div className="flex items-center bg-[#2AA3001A] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[#2AA300] text-[12px] leading-[14.06px]">
              Export Data
            </p>{" "}
            <img src={importdata} alt="" />
          </div>
          <div className="flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
              Bulk Action
            </p>
            <img src={bulkaction} alt="" />
          </div>
          <NavLink to="/admin/customers/new_customer">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">Add New</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div> */}

        <div className="flex gap-[15px] flex-wrap">
          <div className="flex items-center bg-[#F1602B] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-white text-[12px] leading-[14.06px]">Action</p>{" "}
            <img src={importdata} alt="" />
          </div>

          <NavLink to="/admin/roles/create">
            <div className="flex items-center bg-[#2aa300]  gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px] text-white">Create</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>

          <div className="flex items-center bg-[#e74e4e4b] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-white">Export</p>
            <img src={bulkaction} alt="" />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {roles === null ? (
        <FormSkeleton titles={["Name", "admins", "Status", "Action"]} />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      <HandlingPagination data={roles} getDataForTable={getDataForTable} />
    </div>
  );
};

export default Roles;
