import { useEffect, useState } from "react";
import { BiCube } from "react-icons/bi";
import { AiOutlineHome, AiOutlineMinusSquare } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";
import { FiServer } from "react-icons/fi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { getToken } from "../utils/tokentUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";
import useGetQuery from "../hooks/useGetQuery";
import General from "./EditPlans/General";
import PlanFeatures from "./EditPlans/PlanFeatures";
import SpeedLimit from "./EditPlans/SpeedLimit";
import CuttingSystem from "./EditPlans/CuttingSystem";
import SendingServer from "./EditPlans/SendingServer";
import Pricing from "./EditPlans/Pricing";
import { useParams } from "react-router-dom";

const EditPlan = () => {
  const [show, setshow] = useState<any>(null);

  const { plan } = useParams();
  const token = getToken();

  //function for get the data for single customerPlans
  const { getQueryData: customerPlans } = useGetQuery(
    `/admin/plans/${plan}/show`,
    `/admin/plans/${plan}/show`
  );

  // function that handles the navigation click of editPlan page.
  const handleNavClick = (name: string) => {
    if (name === show) {
      setshow(show); // if the same dropdown is clicked again, turn it off
    } else {
      setshow(name); // turn on the selected dropdown and turn off others
    }
  };

  //form token and url for plans
  const formDetail = {
    formGeneral: {
      _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
      url: `/admin/plans/${plan}`,
      _method: "PATCH",
    },
    formPlan: {
      _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
      url: `/admin/plans/${plan}/settings`,
      _method: "",
    },
    formSpeed: {
      _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
      url: `/admin/plans/${plan}/speed-limit`,
      _method: "",
    },
    formCutting: {
      _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour33",
      url: `/admin/plans/${plan}/cutting-system`,
      _method: "",
    },
    formPricing: {
      _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
      url: `/admin/plans/${plan}/pricing`,
      _method: "",
    },
  };

  //handle on submit form of editplans
  const handleFormSubmit = async (e, url, data, _method, _token) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiBaseUrl}${url}`,
        { ...data, _method: _method, _token: _token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  useEffect(() => {
    setshow("account");
  }, []);

  return (
    //main container
    <div className="font-sans mb-5">
      {/*Top Div*/}
      <div className="mb-3 mt-3">
        <span className="bg-[#e6e6e6] text-pink-400 rounded-sm text-[13px] px-1">
          A plan, or service plan, is the group of services and limits that you
          have agreed to provide to your customer. A plan allows you to
          centrally plan and standardize your service offerings. Set up your
          plan details below:
        </span>

        <div className="flex py-4 gap-4 md:flex-nowrap flex-wrap justify-center">
          <button
            onClick={() => handleNavClick("account")}
            className={`${
              show === "account"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-2 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <AiOutlineHome />
            GENERAL
          </button>
          <button
            onClick={() => handleNavClick("planFeatures")}
            className={`${
              show === "planFeatures"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <BiCube />
            PLAN FEATURES
          </button>
          <button
            onClick={() => handleNavClick("speedLimit")}
            className={`${
              show === "speedLimit"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <BsFillSendFill />
            SPEED LIMIT
          </button>
          <button
            onClick={() => handleNavClick("cuttingSystem")}
            className={`${
              show === "cuttingSystem"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <AiOutlineMinusSquare />
            CUTTING SYSTEM
          </button>
          <button
            onClick={() => handleNavClick("sendingServers")}
            className={`${
              show === "sendingServers"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <FiServer />
            SENDING SERVERS
          </button>
          <button
            onClick={() => handleNavClick("pricing")}
            className={`${
              show === "pricing"
                ? "bg-[--accent-orange] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <AiOutlineShoppingCart />
            PRICING
          </button>
        </div>
      </div>

      {/*formGeneral started*/}
      {show === "account" && (
        <General
          customerPlans={customerPlans}
          formDetail={formDetail}
          handleFormSubmit={handleFormSubmit}
        />
      )}

      {/*formPlan started*/}
      {show === "planFeatures" && (
        <PlanFeatures
          customerPlans={customerPlans}
          formDetail={formDetail}
          handleFormSubmit={handleFormSubmit}
        />
      )}

      {/*formSpeed started*/}
      {show === "speedLimit" && (
        <SpeedLimit
          customerPlans={customerPlans}
          formDetail={formDetail}
          handleFormSubmit={handleFormSubmit}
        />
      )}

      {/*formCutting started*/}
      {show === "cuttingSystem" && (
        <CuttingSystem
          customerPlans={customerPlans}
          formDetail={formDetail}
          handleFormSubmit={handleFormSubmit}
        />
      )}

      {/*sendingServer started*/}
      {show === "sendingServers" && (
        <SendingServer customerPlans={customerPlans} />
      )}

      {/*formPricing started*/}
      {show === "pricing" && (
        <Pricing
          customerPlans={customerPlans}
          formDetail={formDetail}
          handleFormSubmit={handleFormSubmit}
        />
      )}
    </div>
  );
};

export default EditPlan;
