import React, { useEffect, useState } from "react";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { Switch } from "antd";
import { useToken } from "antd/es/theme/internal";
import axios from "axios";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { getToken } from "../utils/tokentUtils";
import { BsBarChartFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import FormSkeleton from "../components/FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { apiBaseUrl } from "../utils/urls";

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const columns: any = [
  {
    name: "Name",
    selector: (row) => row.current,
  },
  {
    name: "Subscribed By",
    selector: (row) => (
      <>
        <div className="flex gap-[10px] cursor-pointer">
          {" "}
          <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full"></div>
          <div>
            <p className="text-[14px] text-[#5F6368]">{row.name}</p>
            <p className="text-[11px] text-[#5F6368]">{row.email}</p>
          </div>
        </div>
      </>
    ),
  },
  {
    name: "Subscribed On",
    selector: (row) => row.date,
  },
  {
    name: "Ended At",
    selector: (row) => row.end,
  },
  {
    name: "Status",
    selector: (row) => <Switch defaultChecked onChange={onChange} />,
  },
  {
    name: "Action",
    selector: (row) => (
      <div className="flex gap-2 justify-start align-middle ">
        <NavLink to="/admin/customers/edit">
          <MdOutlineCancel
            className="text-[#EA5455] cursor-pointer"
            size={20}
          />
        </NavLink>
        <NavLink to={`/admin/subscriptions/${row.id}/logs`}>
          <BsBarChartFill
            className="text-[#6690eb] cursor-pointer "
            size={20}
          />
        </NavLink>
      </div>
    ),
  },
];

const Subscriptions = () => {
  const [allSubscription, setAllSubscription] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: searchinput,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/subscriptions/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            setAllSubscription(data.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, [searchinput]);

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val) => ({
    id: val.uid,
    name: val.name,
    current: val.subscribed_by,
    email: val.email,
    date: val.start_at,
    end: val.end_at,
  }));

  return (
    <div className="bg-white">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Subscriptions
          </h2>
        </div>
        <div className="flex gap-[15px]">
          <div className="flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
              Bulk Action
            </p>
            <img src={bulkaction} alt="" />
          </div>
          <NavLink to="/admin/subscriptions/create">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">New Subscription</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div>
      </div>
      {/* <------------------------------------------------------------------- */}
      <div className="flex flex-wrap justify-between pl-[37px] pr-[14px] pb-[21px] mt-[18px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {allSubscription === null ? (
        <FormSkeleton
          titles={[
            "Name",
            "Subscribed By",
            "Subscribed On",
            "Ended At",
            "Status",
            "Action",
          ]}
        />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      <HandlingPagination
        data={allSubscription}
        getDataForTable={getDataForTable}
      />
    </div>
  );
};

export default Subscriptions;
