import { Switch } from "antd";
import axios from "axios";
import { TfiSave } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/tokentUtils";
import { useEffect, useState } from "react";
import { apiBaseUrl } from "../utils/urls";

const UpdateServer = () => {
  const { server } = useParams(); // getting params from URL
  const token = getToken(); // getting token

  console.log(server, "params");
  const [paramsName, setParamsName] = useState<any>(server);
  const [serverData, setServerData] = useState<any>();

  // =========getting dat from api============

  useEffect(() => {
    // setParamsName(server.replace(/['"]+/g, ''))
    console.log(server, "under ueh");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiBaseUrl}/admin/sending-servers/select`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (server) {
          setServerData(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [server]);
  // ===============================================

  // console.log(serverData, "serverData");

  // changing nesed object into nestd arrays....
  const newNestedArr =
    serverData &&
    Object.keys(serverData).map((outerKey) => {
      const innerObj = serverData[outerKey];
      return { ...innerObj };
    });

  // console.log(newNestedArr, "nnn");
  // filtered data based on url params
  const filteredData = newNestedArr?.filter((obj) => obj.settings === server);
  // console.log(filteredData);

  // To convert array of objects into objects
  const mergedObject = filteredData?.reduce((result, currentObject) => {
    return { ...result, ...currentObject };
  }, {});

  // FormState variables
  const [formState, setFormState] = useState<any>({
    _token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
    api_link: "",
    auth_token: "",
    api_key: "",
    mms: "",
    name: "",
    plain: "",
    quota_base: "",
    quota_unit: "",
    quota_value: "",
    schedule: "",
    settings: "",
    sms_per_request: "",
    type: "http",
  });

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      name: mergedObject?.name,
      api_link: mergedObject?.api_link,
      api_key: mergedObject?.api_key,
      auth_token: mergedObject?.auth_token,
      quota_value: mergedObject?.quota_value,
      quota_base: mergedObject?.quota_base,
      quota_unit: mergedObject?.quota?.unit,
      sms_per_request: mergedObject?.sms_per_request,
      plain: mergedObject?.plain,
      schedule: mergedObject?.schedule,
    }));
  }, [serverData]);

  // Updates the editSendingForm state when an input field value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the editSendingForm state when an select element field value changes
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the editSendingForm state when an switch element field value changes
  const handleSwitchChange = (e, name: string) => {
    setFormState((prev) => ({
      ...prev,
      [name]: !formState?.[name],
    }));
  };

  console.log(mergedObject, "mereee");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formState);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="containers py-4 ">
          <div className="update-credentials w-[50%] mt-4 p-4 bg-white rounded-md">
            <p className="title w-full  py-4 text-[1.285rem] font-[500] text-[var(--accent-gray)]  ">
              Update credentials
            </p>
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor="name"
              >
                Name
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={formState?.name}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              />
            </div>
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor="api_link"
              >
                API Link
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="api_link"
                name="api_link"
                type="text"
                value={formState?.api_link}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              />
            </div>
            {mergedObject?.auth_token ? (
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                  htmlFor="app_name"
                >
                  Auth Token
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  name="app_name"
                  id="app_name"
                  value={mergedObject?.auth_token}
                  onChange={handleInputChange}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                />
              </div>
            ) : (
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                  htmlFor="api_key"
                >
                  Api Key
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  name="api_key"
                  id="api_key"
                  value={mergedObject?.api_key}
                  onChange={handleInputChange}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                />
              </div>
            )}
          </div>

          <div className="sending-limit w-[50%] mt-4 p-4 bg-white rounded-md">
            <p className="title w-full  py-4 text-[1.285rem] font-[500] text-[var(--accent-gray)]  ">
              Sending limit
            </p>
            <p className="text-justify mb-4 text-[var(--accent-gray)]  leading-[1.5rem] form-label-size  ">
              The configuration setting below allows you to set a limit on sms
              sending speed. For example, to limit sending speed to{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                500 sms every 5 minutes{" "}
              </span>
              , you can set{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c]">
                Sending limit = 500,
              </span>{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c]">
                Time value = 5, and Time unit = minute
              </span>{" "}
              accordingly. Also set{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c]">
                amount
              </span>{" "}
              of{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c]">
                several messages
              </span>{" "}
              with a{" "}
              <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c]">
                single HTTP request.
              </span>
            </p>

            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor="quota_value"
              >
                Sending limit
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="quota_value"
                name="quota_value"
                type="number"
                value={formState?.quota_value}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              />
            </div>
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor="quota_base"
              >
                Time Base
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="quota_base"
                name="quota_base"
                type="number"
                value={formState?.quota_base}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              />
            </div>
            <div className="flex gap-2 mb-6 flex-col ">
              <label
                htmlFor="quota_unit"
                className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
              >
                Time Unit<span className="text-red-600"> *</span>
              </label>
              <select
                id="quota_unit"
                name="quota_unit"
                value={formState?.quota_unit}
                onChange={handleSelectChange}
                className="rounded-md w-full text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] "
              >
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
              </select>
            </div>
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                htmlFor="sms_per_request"
              >
                SMS per Single Request
                <span className="text-red-600"> *</span>
              </label>
              <input
                id="sms_per_request"
                name="sms_per_request"
                type="number"
                value={formState?.sms_per_request}
                onChange={handleInputChange}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              />
            </div>
          </div>

          <div className="sending-limit w-[50%] mt-4 p-4 bg-white rounded-md">
            <p className="title w-full  py-4 text-[1.285rem] font-[500] text-[var(--accent-gray)]  ">
              Available Features
            </p>

            <div className="switch-container flex gap-3 ">
              <div className="flex flex-col gap-2">
                <label>Plain</label>
                <Switch
                  onChange={(e) => handleSwitchChange(e, "plain")}
                  checked={formState?.plain}
                  className="w-[10px] bg-[#f08b38] "
                  style={{
                    backgroundColor: formState.plain ? "#f08b38" : "#E2E2E2",
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Schedule</label>
                <Switch
                  onChange={(e) => handleSwitchChange(e, "schedule")}
                  checked={formState?.schedule}
                  className="w-[10px] bg-[#f08b38] "
                  style={{
                    backgroundColor: formState.schedule ? "#f08b38" : "#E2E2E2",
                  }}
                />
              </div>
            </div>
            <div className="c-btn  flex justify-end mt-4">
              <button className="accent-btn">
                <TfiSave />
                save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateServer;
