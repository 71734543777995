import React, {useState} from 'react'
import create from "../assets/create.png"
import { showSuccessfulToastAlert } from '../utils/toastAlerts';

const Addnewplan = () => {

    const [form, setForm] = useState({
        name: '',
        price: '',
        billing_cycle: "",
        currency_id: "",
        show_in_customer: "",
        tax_billing_required: "",
        is_popular: "",
      });
     //onchange function for add new plan state

      const handleChange = event => {
        const { name, value } = event.target;
        setForm(prevState => ({ ...prevState, [name]: value }));
      };

 //onchange function for form checkbox
 const onChangeCheckBox = (e, name, togFun) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value === "true" ? false : true,
      };
    });
    togFun();
  };

  //checked state for form
  const [checked01, setchecked01] = useState<boolean>();
  const [checked02, setchecked02] = useState<boolean>();
  const [checked03, setchecked03] = useState<boolean>();

  //toggle function that  wil toggle check and uncheck
  const togFun01 = () => {
    setchecked01(!checked01);
  };
  const togFun02 = () => {
    setchecked02(!checked02);
  };
  const togFun03 = () => {
    setchecked03(!checked03);
  };
  //onchange function for selectbox of new plan
  const onChangeSelectBox = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

    return (
        <div className='bg-white pb-[22px]'>
            <div className='mt-[28px] border-b-[1px] border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[15px] pl-[13.74px] pb-[23px]'>Add a New Plan</h2>
            </div>
            {/* -------------------------------------------------------------- */}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-[6] mx-4'>
                <div className='pt-[47px]'>
                    <form action="" onSubmit={(e)=>{e.preventDefault();console.log(form);showSuccessfulToastAlert("Successfully updated")}}>
                        <div className='mx-4'>
                            <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Name *</h2>
                            <input name="name" value={form.name} onChange={(e)=>handleChange(e)}  type="text" className='text-sm border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]' required/>
                        </div>
                        <div className='mt-[24px] mx-4'>
                            <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Price *</h2>
                            <input name="price" value={form.price} onChange={(e)=>handleChange(e)} type="number" className='text-sm border border-[#D9D9D9] rounded-[1px] w-full  h-[38px] pl-[14px] outline-none' required/>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-[24px] mt-[31px]'>
                        <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Billiing cycle<span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                name='billing_cycle'
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%]  "
                value={form?.billing_cycle}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
       
            <div className="md:w-[79%] mt-2 flex flex-col mb-[1rem] mx-4">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                currency_id
              </label>
              <select
                name="currency_id"
                id=""
                value={form?.currency_id}
                onChange={(e) => onChangeSelectBox(e, "currency_id")}
                className="rounded-md px-[1rem] text-[--accent-gray] w-[100%]   outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
              >
        { [['Npr',2],['Euro',14]].map((val)=>{
            return(
                <option value={val[1]}>{val[0]}</option>
            )
        })}
              </select>
            </div>
                        </div>
                        <div className='flex items-center gap-[9.21px] mt-[15px] mx-4'>
                        <input
                        name='show_in_customer'
                className="mx-1"
                type="checkbox"
                id="inp2"
                checked={checked01}
                value={form?.show_in_customer}
                onChange={(e) =>
                  onChangeCheckBox(e, "show_in_customer", togFun01)
                }
              />
                            <p className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Shows in Customers.</p>
                        </div>
                        <div className='flex items-center gap-[9.21px] mt-[18px] mb-[18px] mx-4'>
                        <input
                        name='tax_billing_required'
                className="mx-1"
                type="checkbox"
                id="inp3"
                checked={checked02}
                value={form?.tax_billing_required}
                onChange={(e) =>
                  onChangeCheckBox(e, "tax_billing_required", togFun02)
                }
              />
                            <div>
                                <h2 className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Billing Information required ?</h2>
                                <p className='text-[#5F6368] text-[10px] leading-[11.72px] font-normal'>Ask for tax billing information when subscribing to the plan</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-[9.21px] mx-4'>
                        <input
                        name='is_popular'
                className="mx-1"
                type="checkbox"
                id="inp4"
                checked={checked03}
                value={form?.is_popular}
                onChange={(e) => onChangeCheckBox(e, "is_popular", togFun03)}
              />
                            <div>
                                <h2 className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Set as popular ?</h2>
                                <p className='text-[#5F6368] text-[10px] leading-[11.72px] font-normal'>Set this plan as a popular plan</p>
                            </div>
                        </div>
                        <button type='submit' className='bg-[#0092D7] text-white w-[102px] h-[34px] flex  items-center justify-center gap-[8px] mt-[30px] float-right rounded-[3px] cursor-pointer mx-4'>
                            <img src={create} alt="" />
                            <p className='text-[12px] leading-[14px] font-normal'>Create</p>
                        </button>
                    </form>
                </div>
                <div  className='pt-[59px] mx-4 '>
                    <h2 className='text-[#0092D7] text-[13px] leading-[15.23px] font-bold mb-[10px]'>What is a plan ?</h2>
                    <p className='text-[#5F6368] text-[12px] leading-[19.2px] font-text w-[100%]'>A plan, or service plan, is the group of services and limits that you have agreed to provide to your customer. A plan allows you to centrally plan and standardize your service offerings. Set up your plan details.</p>
                </div>
            </div>
        </div>
    )
}

export default Addnewplan