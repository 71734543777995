import axios from "axios";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../utils/urls";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import { useToken } from "antd/es/theme/internal";

const blacklist = (message: String) =>
toast.success(`${message}`, {
position: "top-center",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "light",
});

const AddNewBlacklist = () => {
// const formik = useFormik({
// initialValues: {
// number: "",
// delimiter: "",
// reason: "",
// },
// onSubmit: (values) => {
// alert(JSON.stringify(values, null, 2));
// },
// });
const ref = useRef<any>();
// console.log(ref);
const initialValues = {
number: "",
delimiter: ",",
reason: "",
};

const token = useToken();

const navigate = useNavigate();

const [blackNumber, setBlackNumber] = useState<any>(initialValues);


const handleChange = (e: any) => {
// console.log(e.target.value);

const value = e.target.value;
setBlackNumber({ ...blackNumber, [e.target.name]: value });
};

const blacklistSend = async (e: any) => {
e.preventDefault();
console.log(blackNumber);
try {
var data = {
_token:"1FZeWy6FlC65fTqkHaxzfsQolU4uLLDz2XhWkGVO",
number: blackNumber?.number,
delimiter: blackNumber?.delimiter,
reason: blackNumber?.reason
}

var config = {
method: "post",
maxBodyLength: Infinity,
url: `${apiBaseUrl}/admin/blacklists`,
headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
},
data: data,
};

axios(config)
.then(function (response) {
console.log(JSON.stringify(response.data));
const data = response?.data;
console.log(data)

if (data?.status === "success") {
// Swal.fire({
// position: "top-end",
// icon: "success",
// title: "BlackList Added",
// showConfirmButton: false,
// timer: 1500,
// });
showSuccessfulToastAlert(data?.message)
} else {
// Swal.fire({
// icon: "error",
// title: "Oops...",
// text: "Something went wrong!",
// footer: '<a href="">Why do I have this issue?</a>',
// });
showFailedToastAlert(data?.message)
}
})
.catch(function (error) {
console.log(error);
});
} catch (err: any) {
// validateInput(err.response.data.data);
}
};
// console.log(blackNumber);
const restForm = () => {
ref.current.reset();
};

return (
<div className="bg-white mt-[20px] max-w-[513px] rounded-[5px] pb-[30px]">
<div className="border-b-[1px] border-[#D9D9D9]">
<h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
Add New Blacklist
</h2>
</div>
<form action="" className="pl-[31px]" onSubmit={blacklistSend} ref={ref}>
<p className="mt-[26px] text-[#5F6368] text-[13px] leading-[15.23px] font-normal ">
Paste your{" "}
<span className="text-[#EE7576] form-label-size bg-[#EEEEEE] p-[2px] mr-[4px]">
blacklist numbers
</span>
and choose{" "}
<span className="text-[#EE7576] form-label-size bg-[#EEEEEE] p-[2px] mr-[4px]">
delimiter
</span>
SMSVENDOR{" "}
</p>
<div className="mt-[24px]">
<h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
Paste Numbers *
</h2>
{/* <p className=" text-red-500 text-[10px] font-light">{error.number}</p> */}
<input
type="text"
name="number"
onChange={handleChange}
className="border bg-white border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
/>
</div>
{/* <p className=" text-red-500 text-[10px] font-light">
{error.delimiter}
</p> */}
<div className="mt-[24px] grid grid-cols-5 h-[60px] w-[452px]">
<div className="flex items-center justify-center border flex-col border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group">
<label htmlFor="ee">
{" "}
<p className="group-hover:text-[#0092D7]">,</p>
<p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
Comma
</p>
</label>
<input
type="radio"
name="delimiter"
id="ee"
value=","
className=" opacity-0"
onChange={handleChange}
/>
</div>
<div className="flex items-center justify-center border flex-col border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group">
<label htmlFor="radio-1">
{" "}
<p className="group-hover:text-[#0092D7]">;</p>
<p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
Semi colon
</p>
</label>
<input
type="radio"
name="delimiter"
id="radio-1"
className=" opacity-0"
onChange={handleChange}
value=";"
/>
</div>
<div className="flex items-center justify-center border flex-col border-[#0092D7] border-r-0 hover:bg-[#0092D71A ] group">
<label htmlFor="radio-2">
{" "}
<p className="group-hover:text-[#0092D7]">|</p>
<p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
Bar
</p>
</label>
<input
type="radio"
name="delimiter"
id="radio-2"
value="|"
className=" opacity-0"
onChange={handleChange}
/>
</div>
<div className="flex items-center justify-center border flex-col border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group">
<label htmlFor="radio-3">
{" "}
<p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
Tab
</p>
</label>
<input
type="radio"
id="radio-3"
name="delimiter"
value=","
className=" opacity-0"
onChange={handleChange}
/>
</div>
<div className="flex items-center justify-center border flex-col border-[#0092D7] hover:bg-[#0092D71A] group">
<label htmlFor="radio-4">
{" "}
<p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
New line
</p>
</label>
<input
type="radio"
name="delimiter"
id="radio-4"
value=","
className=" opacity-0"
onChange={handleChange}
/>
</div>
</div>
<div className="mt-[50px]">
<h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
Reason *
</h2>
<input
type="text"
name="reason"
onChange={handleChange}
placeholder=""
className="border bg-white border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
/>
</div>

<div className="flex gap-[12px] mt-[54px] ml-[227px]">
<button type="submit">
<div className="w-[107px] h-[34px] flex items-center justify-center text-white rounded-[2px] cursor-pointer bg-[#0092D7] ">
<p className="text-[14px] leading-[16.41px] font-normal">Save</p>
</div>
</button>
<div onClick={restForm}>
<div className="w-[107px] h-[34px] flex items-center justify-center text-[#5F6368] rounded-[2px] cursor-pointer bg-[#5F63681A] ">
<p className="text-[14px] leading-[16.41px] font-normal">Reset</p>
</div>
</div>

</div>
</form>
</div>
);
};

export default AddNewBlacklist;