import { useState, useEffect } from "react";
import importdata from "../assets/importdata.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BiCart } from "react-icons/bi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import usePostQuery from "../hooks/usePostQuery";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { apiBaseUrl } from "../utils/urls";
import { showSuccessfulToastAlert } from "../utils/toastAlerts";
import DialogBox from "./DialogBox";

const Customer = () => {
  const token = getToken(); // Getting token
  // State variable to store the search input
  const [searchinput, setSearchInput] = useState<any>("");

  // making post request for customer search
  let postData = {
    length: 10,
    start: 0,
    orderBy: "id",
    orderDir: "DESC",
    search: "",
  };

  // usign usePostQuery Hook for getting Customer list
  const { queryData: Customers, isLoading } = usePostQuery(
    "/admin/customers/search",
    "/admin/customers/search",
    postData
  );
  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const [customerstatus, setcustomerstatus] = useState<any>();
  const [verified, setVerified] = useState<any>();

  useEffect(() => {
    Customers?.data.map((val) => setcustomerstatus(val.status));
  }, [Customers]);
  console.log(Customers);

  // columns configuration for Table
  const data = dataForTable
    ?.filter((val) => {
      if (!searchinput) {
        return val;
      } else {
        return val.email.toLowerCase().includes(searchinput.toLowerCase());
      }
    })
    .map((val) => ({
      id: val.uid,
      name: val.name,
      email: val.email,
      avatar: val.avatar,
      current: val.subscription,
      status: customerstatus,
    }));

  const ToggleStatus = async (checked: boolean, id) => {
    console.log(`switch to ${checked}`);
    setcustomerstatus(!checked);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/customers/${id}/active`,
        { _token: "ZAEWHRoL93BAffliGEZqi4E8t2WXWpRPzAoYD6TX" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const ToggleVerified = async (checked: boolean, id) => {
    setVerified(!checked);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/customers/${id}/verify`,
        { _token: "ZAEWHRoL93BAffliGEZqi4E8t2WXWpRPzAoYD6TX" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const columns: any = [
    {
      name: "Name",
      selector: (row) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full">
              <img src={row.avatar} alt="" className="cursor-pointer" />
            </div>
            <div>
              <p className="text-[14px] text-[#5F6368]">{row.name}</p>
              <p className="text-[11px] text-[#5F6368]">{row.email}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Current Plan",
      selector: (row) => row.current,
    },
    {
      name: "Status",
      selector: (row) => (
        <Switch
          checked={row.status}
          onChange={() => {
            ToggleStatus(row.status, row.id);
            console.log(row);
          }}
        />
      ),
    },
    {
      name: "Verified",
      selector: (row) => (
        <Switch
          checked={row.verified}
          onChange={() => {
            ToggleVerified(row.verified, row.id);
          }}
        />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-4 ">
          <DialogBox title="delete" deleteUrl={`/admin/customers/${row.id}`} />
          <NavLink to={`/admin/customers/${row.id}/show`}>
            <FiEdit className="text-[#7367F0] cursor-pointer" size={20} />
          </NavLink>
          <NavLink to={`/admin/subscriptions/create?customer_id=${row.id}`}>
            <BiCart className="text-[#10D2E9] cursor-pointer" size={25} />
          </NavLink>
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white ">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Customers
          </h2>
        </div>
        <div className="flex gap-[15px] flex-wrap">
          <div className="flex items-center bg-[#2AA3001A] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[#2AA300] text-[12px] leading-[14.06px]">
              Export Data
            </p>{" "}
            <img src={importdata} alt="" />
          </div>

          <NavLink to="/admin/customers/new_customer">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">Add New</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {isLoading ? (
        <FormSkeleton titles={["Name", "Current Plan", "Status", "Action"]} />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      <HandlingPagination
        data={Customers?.data}
        getDataForTable={getDataForTable}
      />
    </div>
  );
};

export default Customer;
