import React, { useState, useEffect } from "react";
import importdata from "../assets/importdata.png";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BiCart } from "react-icons/bi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { apiBaseUrl } from "../utils/urls";
import parser from "html-react-parser";
import ViewMessageModalComponent from "./ViewMessageModalComponent";

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const Reports = () => {
  // ===========to get Reports Data ==============
  // const { getQueryData:Reports } = useGetQuery(
  //   `https://smsvendor.com/api/v1/admin/reports`,
  //   `https://smsvendor.com/api/v1/admin/reports`
  // );
  // console.log(Reports)

  const [Reports, setReports] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });
  const modalHandle = (id: any) => {
    // console.log(id);
    setModal({ open: !modal?.open, id: id });
  };
  const columns: any = [
    {
      name: "Date",
      selector: (row) => (
        // <Moment format="  Do MMMM, YYYY  ">{row.created_at}</Moment>
        <div>{row?.created_at}</div>
      ),
    },
    {
      name: "Direction",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#07499A]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.send_by}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "User",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#07499A]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.user_id}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "Type",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#F47C30]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.sms_type}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "From",
      selector: (row) => row.from,
    },
    {
      name: "To",
      selector: (row) => row.to,
    },
    {
      name: "Cost",
      selector: (row) => row.cost,
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={` ${
            row.status === "Delivered" ? "bg-green-500 " : "bg-red-500"
          } capitalize font-normal text-[12px]  text-white px-3 py-1 rounded-[5px] w-full `}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => {
        // console.log(row);
        return (
          <div className=" flex items-center gap-3 justify-center ">
            <div
              onClick={() => {
                modalHandle(row?.uid);
              }}
            >
              <svg
                fill="#F79236"
                width="19px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5.5A2.59 2.59 0 0 0 5.33 8 2.59 2.59 0 0 0 8 10.5 2.59 2.59 0 0 0 10.67 8 2.59 2.59 0 0 0 8 5.5zm0 3.75A1.35 1.35 0 0 1 6.58 8 1.35 1.35 0 0 1 8 6.75 1.35 1.35 0 0 1 9.42 8 1.35 1.35 0 0 1 8 9.25z" />
                <path d="M8 2.5A8.11 8.11 0 0 0 0 8a8.11 8.11 0 0 0 8 5.5A8.11 8.11 0 0 0 16 8a8.11 8.11 0 0 0-8-5.5zm5.4 7.5A6.91 6.91 0 0 1 8 12.25 6.91 6.91 0 0 1 2.6 10a7.2 7.2 0 0 1-1.27-2A7.2 7.2 0 0 1 2.6 6 6.91 6.91 0 0 1 8 3.75 6.91 6.91 0 0 1 13.4 6a7.2 7.2 0 0 1 1.27 2 7.2 7.2 0 0 1-1.27 2z" />
              </svg>
            </div>
            <div
              // onClick={() => deleteMessage(row?.uid)}
              className="cursor-pointer"
            >
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
                  fill="#EF4444"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/reports/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setReports(data?.data.data);
          } else {
            showFailedToastAlert(`Getting Reports error ${data?.message}`);
          }
        })
        .catch((error) => {
          console.log(`Getting Reports error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val) => ({
    id: val.uid,
    name: val.name,
    email: val.email,
    avatar: val.avatar,
    current: val.subscription,
    created_at: val.created_at,
    send_by: val.send_by,
    sms_type: val.sms_type,
    from: val.from,
    to: val.to,
    cost: val.cost,
    status: val.status,
    user_id: val.user_id,
    uid: val.uid,
  }));

  return (
    <div className="bg-white ">
      <ViewMessageModalComponent
        modalHandle={modalHandle}
        modalOpen={modal?.open}
        messageId={modal?.id}
      />
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Reports
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {Reports === null ? (
        <FormSkeleton titles={["Name", "Current Plan", "Status", "Action"]} />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}
      <HandlingPagination data={Reports} getDataForTable={getDataForTable} />
    </div>
  );
};

export default Reports;
