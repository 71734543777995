import { useState, useEffect } from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { HiOutlineUpload } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TfiSave } from "react-icons/tfi";
import { VscAccount } from "react-icons/vsc";
import { BiInfoCircle } from "react-icons/bi";
import { FiLock } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { Switch } from "antd";
import DialogBox from "./DialogBox";
import { showFailedToastAlert } from "../utils/toastAlerts";
import Security from "./AccountProfile/Security";
import Notifications from "./AccountProfile/Notifications";
import AccountSection from "./AccountProfile/AccountSection";
import { useLocation } from "react-router-dom";
import { apiBaseUrl } from "../utils/urls";

const Account = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  console.log(searchParams, "searchParams");
  console.log(tab, "tbbbb");

  // // for showing diffrent forms like accounts, permission, subscription etc
  const [show, setshow] = useState<any>("account");

  // function that handles the navigation click of account page.
  const handleNavClick = (name: string) => {
    setshow(name); // turn on the selected dropdown and turn off others
  };

  let postData = {
    length: 10,
    start: 0,
    orderBy: "message",
    orderDir: "asc",
    search: "",
  };

  const [searchinput, setSearchInput] = useState<any>("");
  const [notifications, setNotifications] = useState<any>(null);

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "message",
        orderDir: "asc",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/users/account/notifications`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          const data = response?.data;
          console.log(data);
          if (data?.status === "success") {
            console.log(data);
            setNotifications(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  console.log(notifications);

  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue: any) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val: any) => ({
    id: val.uid,
    message: val.message,
    mark_read: val.mark_read,
    notification_type: val.notifications_type,
  }));

  const columns: any = [
    {
      name: "TYPE",
      selector: (row: any) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div>
              <p className="text-[14px] text-[#5F6368]">
                {row.notification_type}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "MESSAGE",
      selector: (row) => row.message,
    },
    {
      name: "MARK AS READ",
      selector: (row) => <Switch checked={row.mark_read} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => (
        <div className="flex gap-4 ">
          <DialogBox title="delete" deleteUrl={`/admin/custome/${row.id}`} />
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   if (tab === "notification") {
  //     setshow("notifications");
  //   } if(tab === null){
  //     setshow("account")
  //   }else {
  //     setshow("account")
  //   }
  // }, []);
  // useEffect(() => {
  //   if (tab === "notification") {
  //     setshow("notifications");
  //   } else{
  //     setshow("account")
  //   }
  // }, [searchParams]);

  return (
    <>
      <div className="p-2">
        <div className="flex py-4 gap-4">
          <button
            onClick={() => handleNavClick("account")}
            className={`${
              show === "account"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent  text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center  `}
          >
            <VscAccount />
            Account
          </button>

          <button
            onClick={() => handleNavClick("security")}
            className={`${
              show === "security"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center `}
          >
            <BiInfoCircle />
            security
          </button>

          <button
            onClick={() => handleNavClick("notifications")}
            className={`${
              show === "notifications"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center  `}
          >
            <FiLock />
            Notifications
          </button>
        </div>

        {/* form1 --- account sections started */}
        {show === "account" && <AccountSection />}

        {/* account sections ended */}

        {/* security  section started */}
        {show === "security" && <Security />}

        {/* security section ended */}

        {/* notifications  section started */}
        {show === "notifications" && <Notifications />}
      </div>
    </>
  );
};

export default Account;
