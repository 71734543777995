import React from 'react'
import { BiSave } from 'react-icons/bi'

const LicenseSection = () => {
  return (
    <>
    <div className="w-[100%] mt-[2rem]  px-4">
            <form action="">
              <div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center  justify-evenly  ">
                    <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                    <label className="flex-1 text-center w-full text-[#7367F0] text-[13.125px] mb-[0.2875] ">
                      Your current license
                    </label>
                    <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                  </div>
                  <p className="text-[var(--accent-gray)] form-label-size ">
                    Thank you for purchasing Ultimate SMS! Below is your license
                    key, also known as Purchase Code. Your license type is
                    Regular License
                  </p>
                  <p className="text-[var(--accent-gray)] text-2xl font-[500] form-label-size ">
                    b9b27149-c6e2-44c2-b29f-ccbbd36b7852
                  </p>
                </div>

                <div className="flex flex-col mb-6 gap-4">
                  <div className="flex items-center  justify-evenly  ">
                    <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                    <label className="flex-1 text-center w-full text-[#7367F0] text-[13.125px] mb-[0.2875] ">
                      license types
                    </label>
                    <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                  </div>

                  <p className="text-[var(--accent-gray)] form-label-size">
                    When you purchase Ultimate SMS from Envato website, you are
                    actually purchasing a license to use the product. There are
                    2 types of license that are issued
                  </p>

                  <div>
                    <p className="text-[var(--accent-gray)] text-[#9793A4] text-xl font-[500]  ">
                      Regular License
                    </p>
                    <p className="text-[var(--accent-gray)] text-[#9793A4]  form-label-size ">
                      All features are available, for a single end product which
                      end users are NOT charged for
                    </p>
                  </div>

                  <div>
                    <p className="text-[var(--accent-gray)] text-xl font-[500]  ">
                      Extended License
                    </p>
                    <p className="text-[var(--accent-gray)]  form-label-size ">
                      All features are available, for a single end product which
                      end users can be charged for (software as a service)
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex items-center  justify-evenly  ">
                  <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                  <label className="flex-1 text-center w-full text-[#7367F0] text-[13.125px] mb-[0.2875] ">
                    Update your license
                  </label>
                  <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                </div>

                <div className="flex gap-2 mb-6 flex-col">
                  <label
                    className="text-[var(--accent-gray)] text-[0.857rem] mb-[0.2875] "
                    htmlFor=""
                  >
                    Insert your purchase code
                    <span className="text-red-600"> *</span>
                  </label>
                  <input
                    className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                    type="text"
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <button className="hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn ">
                  <BiSave />
                  Update
                </button>
              </div>
            </form>

            {/* ==================license-form-7 ended================= */}
          </div></>
  )
}

export default LicenseSection