import { useState, useEffect } from "react";
import importdata from "../assets/importdata.png";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { apiBaseUrl } from "../utils/urls";

const columns: any = [
  {
    name: "NUMBER",
    selector: (row) => row.number,
  },
  {
    name: "ASSIGN TO",
    selector: (row) => (
      <div className="flex">
        <div className="h-4 w-4 rounded "></div>
        <div>
          <div>{row.user_id}</div>
          <div>{row.email}</div>
        </div>
      </div>
    ),
  },
  {
    name: "PRICE",
    selector: (row) => row.price,
  },
  {
    name: "Status",
    selector: (row) => <div className="p-2">{row.status}</div>,
  },
  {
    name: "CAPABILITIES",
    selector: (row) => (
      <div dangerouslySetInnerHTML={{ __html: row.capabilities }} />
    ),
  },
  {
    name: "Action",
    selector: (row) => (
      <div className="flex gap-4 ">
        <NavLink to="/admin/customers/edit">
          <AiOutlineDelete
            className="text-[#EA5455] cursor-pointer "
            size={25}
          />
        </NavLink>
        <NavLink to="/admin/customers/edit">
          <FiEdit className="text-[#7367F0] cursor-pointer" size={20} />
        </NavLink>
      </div>
    ),
  },
];

const PhoneNumbers = () => {
  const [PhoneNumber, setPhoneNumber] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");
  console.log(PhoneNumber);
  console.log(searchinput);

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: searchinput,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/phone-numbers/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setPhoneNumber(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = dataForTable
    ?.filter((val) => {
      if (searchinput === "") {
        return val;
      } else if (val.number.toLowerCase().includes(searchinput.toLowerCase())) {
        return val;
      }
      return val;
    })
    .map((val) => ({
      id: val.uid,
      number: val.number,
      user_id: val.user_id,
      email: val.email,
      status: val.status,
      price: val.price,
      capabilities: val.capabilities,
    }));

  return (
    <div className="bg-white ">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            NUMBERS
          </h2>
        </div>
        <div className="flex gap-[15px] flex-wrap">
          <div className="flex items-center bg-[#F1602B] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-white text-[12px] leading-[14.06px]">Action</p>{" "}
            <img src={importdata} alt="" />
          </div>

          <NavLink to="/admin/phone-numbers/create">
            <div className="flex items-center bg-[#2aa300]  gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px] text-white">Create</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>

          <div className="flex items-center bg-[#F1602B] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-white">Export</p>
            <img src={bulkaction} alt="" />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {PhoneNumber === null ? (
        <FormSkeleton
          titles={[
            "NUMBER",
            "ASSIGN TO",
            "PRICE",
            "STATUS",
            "CAPABILITIES",
            "ACTIONS",
          ]}
        />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}

      <HandlingPagination
        data={PhoneNumber}
        getDataForTable={getDataForTable}
      />
    </div>
  );
};

export default PhoneNumbers;
