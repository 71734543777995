import { useEffect, useState } from "react";
import { getToken } from "../../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../../hooks/useGetQuery";
import usePostQuery from "../../hooks/usePostQuery";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import { TfiSave } from "react-icons/tfi";
import { apiBaseUrl } from "../../utils/urls";

const Information = () => {
  const token = getToken();
  const { customer } = useParams();

  //function for get the data for single customer
  const { getQueryData: customerData } = useGetQuery(
    `/admin/customers/${customer}/show`,
    `/admin/customers/${customer}/show`
  );

  // getting all countries
  let postDataForCountries = JSON.stringify({
    length: 100,
    start: 0,
    orderBy: "id",
    orderDir: "asc",
    search: "",
  });

  const { queryData: countries } = usePostQuery(
    "/admin/countries/search",
    "/admin/countries/search",
    postDataForCountries
  );

  //state of form2
  const [form2, setForm2] = useState({
    phone: "",
    company: "",
    website: "",
    address: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    _token: "542Jw2vidfHRTby4MLJ56KRv7pmfI5ExoLA3eZdY",
  });

  useEffect(() => {
    setForm2((prevForm2) => ({
      ...prevForm2,
      phone: customerData?.customer?.customer?.phone,
      company: customerData?.customer?.customer?.company,
      website: customerData?.customer?.customer?.website,
      address: customerData?.customer?.customer?.address,
      city: customerData?.customer?.customer?.city,
      state: customerData?.customer?.customer?.state,
      postcode: customerData?.customer?.customer?.postcode,
      country: customerData?.customer?.customer?.country,
    }));
  }, [customerData]);

  const handleForm2Change = (event) => {
    const { name, value } = event.target;
    setForm2((prevForm2) => ({
      ...prevForm2,
      [name]: value,
    }));
  };

  // handling form2 submit ---- information section
  const handleForm2Submit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/customers/${customer}/update-information`,
        { ...form2 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  return (
    <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
      <div className=" pb-4 border-b ">
        <p className="text-2xl  text-[#5f6368]  ">Personal Information</p>
      </div>

      {/* -------form part 2----- */}
      <form onSubmit={(e) => handleForm2Submit(e)}>
        <div className="bottom my-4  p-2 flex flex-col md:flex-row flex-wrap">
          <div className="left md:w-[50%] w-[100%] md:pr-2">
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Phone<span className="text-red-600"> *</span>
              </label>
              <input
                value={form2.phone}
                type="number"
                onChange={handleForm2Change}
                name="phone"
                required
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              />
            </div>

            <div className="flex flex-col  mb-[16px]  ">
              <div className="  flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Company
                </label>
                <input
                  value={form2.company}
                  type="text"
                  onChange={handleForm2Change}
                  name="company"
                  className="rounded-[0.357rem] relative text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem]"
                />
              </div>
            </div>

            <div className="flex flex-col mb-[16px]  ">
              <div className=" flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Website
                </label>
                <input
                  value={form2.website}
                  type="text"
                  onChange={handleForm2Change}
                  name="website"
                  className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem] "
                />
              </div>
            </div>

            <div className="flex flex-col mb-[16px]  ">
              <div className=" flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Address<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form2.address}
                  type="text"
                  onChange={handleForm2Change}
                  name="address"
                  required
                  className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem] "
                />
              </div>
            </div>
          </div>
          <div className="Right md:w-[50%] w-[100%] md:pl-2">
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                City<span className="text-red-600"> *</span>
              </label>
              <input
                value={form2.city}
                type="text"
                onChange={handleForm2Change}
                name="city"
                required
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              />
            </div>

            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                State
              </label>
              <input
                value={form2.state}
                type="text"
                onChange={handleForm2Change}
                name="state"
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              />
            </div>

            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Postcode
              </label>
              <input
                value={form2.postcode}
                type="text"
                onChange={handleForm2Change}
                name="postcode"
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              />
            </div>

            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Country<span className="text-red-600"> *</span>
              </label>
              <select
                onChange={handleForm2Change}
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem]"
              >
                <option value={form2.company}>{form2.country}</option>
                {countries?.data?.map((val, index) => (
                  <option key={index} value={val?.name}>
                    {val?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
            >
              <TfiSave />
              save changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Information;
