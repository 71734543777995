import axios from "axios";
import { useState, useEffect } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { TfiSave } from "react-icons/tfi";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import { getToken } from "../../utils/tokentUtils";
import { apiBaseUrl } from "../../utils/urls";

const Security = () => {
  const token = getToken();

  // state to show password and hide
  const [showCurrentPassword, setShowCurrentPassword] = useState<any>(false);
  const [Password, setPassword] = useState<any>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(false);

  const [securityForm, setSecurityForm] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
    _token: "CFKuZ0TOg0Q2XcJePbFKAM1bZVz46Sox1HiQEMPS",
  });

  // onchange function for inputs fields
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setSecurityForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // handling securityForm submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = JSON.stringify(securityForm);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/users/account/change-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  return (
    <>
      <div className="p-2">
        <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
          {/* -------form part 2----- */}
          <div className="bottom my-4  p-2">
            <form action="" className="flex gap-4">
              <div className="left w-[50%]">
                <div className="flex flex-col gap-5  mb-[16px]  ">
                  <div className="relative gap-1 flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor="current_password"
                    >
                      Current Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      id="current_password"
                      name="current_password"
                      value={securityForm?.current_password}
                      type={showCurrentPassword ? "text" : "password"}
                      onChange={onChangeFun}
                      required
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                    />
                    {showCurrentPassword ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      />
                    )}
                  </div>
                  <div className="relative gap-1 flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor="password"
                    >
                      New Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      id="password"
                      name="password"
                      value={securityForm.password}
                      type={Password ? "text" : "password"}
                      onChange={onChangeFun}
                      required
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                    />
                    {Password ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() => setPassword(!Password)}
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() => setPassword(!Password)}
                      />
                    )}
                  </div>
                  <div className="relative gap-1  flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor="password_confirmation"
                    >
                      Confirm new Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      id="password_confirmation"
                      name="password_confirmation"
                      value={securityForm.password_confirmation}
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={onChangeFun}
                      required
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                    />
                    {showConfirmPassword ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleSubmit}
                className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
              >
                <TfiSave />
                save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
