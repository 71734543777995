import React,{useState,useEffect} from 'react'
import importdata from "../assets/importdata.png"
import bulkaction from "../assets/bulkaction.png"
import addnew from "../assets/addnew.png"
import search from "../assets/search.png"
import DataTable from 'react-data-table-component';
import { Switch } from 'antd';
import axios from 'axios'
import { getToken } from '../utils/tokentUtils'
import { showFailedToastAlert } from '../utils/toastAlerts'
import { NavLink } from 'react-router-dom'
import { AiOutlineDelete} from 'react-icons/ai'
import { FiEdit} from 'react-icons/fi'
import FormSkeleton from './FormSkeleton'
import HandlingPagination from './HandlingPagination'
import DialogBox from './DialogBox'
import usePostQuery from '../hooks/usePostQuery'



const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
};


const columns: any = [
    {
        name: 'SENDER ID',
        selector: row =><div className='flex gap-[10px] cursor-pointer'>
        <p className='text-[14px] text-[#5F6368]'>{row.sender_id}</p>
        </div>,
    },
    {
        name: 'ASSIGN TO',
        selector: row => <><div className='flex gap-[10px] cursor-pointer'> <div className='h-[25px] w-[25px] bg-[#D9D9D9] rounded-full'>
        <img src={row.avatar} alt="" className='cursor-pointer' />
      </div>
      <div><p className='text-[14px] text-[#5F6368]' dangerouslySetInnerHTML={{__html:row.user_id}} ></p>
          <p className='text-[11px] text-[#5F6368]'>{row.email}</p>
      </div></div></>,
    },
    {
        name: 'PRICE',
        selector: row => row.price,
    },
    {
        name: 'Status',
        selector:row=><Switch checked={row.status==="active"? true:false} onChange={onChange} />
        // cell: row => <> <label className="switch">
        //     <input type="checkbox" checked />
        //     <span className="slider round"></span>
        // </label></>
    },
    {
        name: 'Action',
        selector: row => <div className='flex gap-4 '>
          <DialogBox title="delete" deleteUrl={`/admin/custome/${row.id}`}/>
         <NavLink to={`/admin/senderid/${row.id}/show`} ><FiEdit className='text-[#7367F0] cursor-pointer' size={20} /></NavLink> 
        </div>,
    },
];




const SenderId = () => {

    const [searchinput,setSearchInput] = useState<any>("");
    console.log(searchinput)
    
    // making post request for senderid search
    let postData = {
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "DESC",
      search: "",
    };

    // usign usePostQuery Hook for getting senderid list
  const { queryData:SenderId, isLoading, } = usePostQuery("/admin/senderid/search","/admin/senderid/search",postData)
 
    // data that have been returned from pagination.
    const [dataForTable, setTable] = useState<any>();
    const getDataForTable = (paginatedValue)=>{
      setTable(paginatedValue)
    }

  const data = dataForTable?.filter((val)=>{
    if(searchinput===""){
      return val
    }else {
      return val.sender_id.toLowerCase().includes(searchinput.toLowerCase())
    }
    return val
  }).map((val)=>(
    {
        id: val.uid,
        sender_id: val.sender_id,
        email: val.email,
        avatar:val.avatar,
        price: val.price,
        status:val.status,
        user_id:val.user_id
    }
  ))

  

    return (
        <div className='bg-white '>
            <div className='mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]'>
                <div className='flex items-center'>
                    <h2 className='text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]'>SenderId</h2>
                </div>
                <div className='flex gap-[15px] flex-wrap'>
                    <div className='flex items-center bg-[#F1602B] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-white text-[12px] leading-[14.06px]'>Action</p> <img src={importdata} alt="" />
                    </div>
                    
                    <NavLink to="/admin/senderid/create">
                    <div className='flex items-center bg-[#2aa300]  gap-[6px] p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[12px] leading-[14.06px] text-white'>Create</p>
                        <img src={addnew} alt="" />
                    </div>
                    </NavLink>

                    <div className='flex items-center bg-[#e74e4e4b] gap-[6px] p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[12px] leading-[14.06px] text-white'>Export</p>
                        <img src={bulkaction} alt="" />
                    </div>
                    <NavLink to="/admin/senderid/plan">
                    <div className='flex items-center bg-[#F1602B] gap-[6px] p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[12px] leading-[14.06px] text-white'>Create Plan</p>
                        <img src={bulkaction} alt="" />
                    </div>
                    </NavLink>

                </div>
            </div>
            <div className='flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]'>
                <div className='flex items-center gap-[8px]'>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Show</p>
                    <select name="num" id="num" className=' border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer'>
                        <option value="10">10</option>
                        <option value="9">9</option>
                        <option value="8">8</option>
                        <option value="7">7</option>
                    </select>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Entries</p>
                </div>
                <div className='border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between'>
                    <input onChange={(e)=>setSearchInput(e.target.value)} type="text" placeholder='Search' className='pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal' />
                    <img src={search} alt="" className='cursor-pointer' />
                </div>
            </div>


            { isLoading ? <FormSkeleton titles={['SENDER ID','ASSIGN TO',"PRICE","STATUS","ACTIONS"]} />:
            <DataTable
                columns={columns}
                data={data}
                selectableRows
                className='px-[10px] h-[550px]'
            />}

            <HandlingPagination data={SenderId?.data} getDataForTable={getDataForTable} />

        </div>
    )
}

export default SenderId