import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";

export default function useGetQuery(key, url) {
  const token = getToken();

  const fetchPostData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data.data;
    } catch (error:any) {
      throw new Error(error.response.data.message);
    }
  };

  const { data: getQueryData, isError:getRequestError, isLoading:getLoading } = useQuery([key], fetchPostData);

  return { getQueryData, getLoading, getRequestError };
}
