import React, { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";
import { getToken } from "../utils/tokentUtils";
import { apiBaseUrl } from "../utils/urls";
import axios, { AxiosRequestConfig } from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";

const CreateOwnServer = () => {
  const token = getToken(); //Getting token

  // state variables
  const [serverData, setServerData] = useState<any>({
    _token: "wqqkaaJz0yYXhpkc1PDEdA8gC8sef46SD8F2109U",
    name: "",
    api_link: "",
    success_keyword: "",
    http_request_method: "",
    json_encoded_post: "",
    content_type: "",
    content_type_accept: "",
    character_encoding: "",
    ssl_certificate_verification: "",
    authorization: "",
    plain: "",
    schedule: "",
    quota_value: "",
    quota_base: "",
    quota_unit: "",
    sms_per_request: "",
    multi_sms_delimiter: "",
    username_param: "",
    username_value: "",
    password_param: "",
    password_value: "",
    password_status: "",
    action_param: "",
    action_value: "",
    action_status: "",
    source_param: "",
    source_value: "",
    source_status: "",
    destination_param: "",
    message_param: "",
    unicode_param: "",
    unicode_value: "",
    unicode_status: "",
    route_param: "",
    route_value: "",
    route_status: "",
    language_param: "",
    language_value: "",
    language_status: "",
    custom_one_param: "",
    custom_one_value: "",
    custom_one_status: "",
    custom_two_param: "",
    custom_two_value: "",
    custom_two_status: "",
    custom_three_param: "",
    custom_three_value: "",
    custom_three_status: "",
  });

  // Update serverData state when components renders
  useEffect(() => {
    setServerData((prev) => ({
      ...prev,
      http_request_method: "get",
      json_encoded_post: 0,
      content_type: "none",
      content_type_accept: "none",
      character_encoding: "none",
      ssl_certificate_verification: 1,
      authorization: "no_auth",
      plain: 1,
      schedule: 1,
      quota_value: 60,
      quota_base: 1,
      quota_unit: "minute",
      sms_per_request: 1,
      multi_sms_delimiter: ",",
      password_status: 0,
      action_status: 0,
      source_status: 0,
      unicode_status: 0,
      route_status: 0,
      language_status: 0,
      custom_one_status: 0,
      custom_two_status: 0,
      custom_three_status: 0,
    }));
  }, []);

  // Updates the serverData state when an input field value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setServerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the serverData state when an select element field value changes
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setServerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handles form submission asynchronously
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      const data = JSON.stringify(serverData); //Converts serverData to a JSON string
      // Configuration object for the HTTP request
      let config: AxiosRequestConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/sending-servers/custom-server/create`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data, // Includes the JSON data in the request body
      };

      const response = await axios.request(config); // Sends the HTTP request using axios

      showSuccessfulToastAlert(response?.data.message); // Shows a success toast alert with the response message
    } catch (error) {
      showFailedToastAlert(error); // Shows a failed toast alert with the error
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="main  my-[15px] font-sans flex md:flex-row flex-col gap-4 w-[100%]">
          {/*left container*/}
          <div className="left flex flex-col w-[100%] md:w-[28%]   gap-4">
            <div className="bg-white shadow-md rounded-md w-[100%]">
              <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4">
                Create Your Own Server
              </h3>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="name"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Name<span className="text-red-600"> *</span>
                </label>
                <input
                  required
                  id="name"
                  name="name"
                  type="text"
                  value={serverData?.name}
                  onChange={handleInputChange}
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%] "
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="api_link"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Base URL<span className="text-red-600"> *</span>
                </label>
                <input
                  required
                  id="api_link"
                  name="api_link"
                  type="text"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
                  value={serverData.api_link}
                  onChange={handleInputChange}
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="success_keyword"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Success Keyword<span className="text-red-600"> *</span>
                </label>
                <input
                  required
                  id="success_keyword"
                  name="success_keyword"
                  type="text"
                  className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
                  value={serverData.success_keyword}
                  onChange={handleInputChange}
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="http_request_method"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  HTTP Request Method<span className="text-red-600"> *</span>
                </label>
                <select
                  id="http_request_method"
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                  name="http_request_method"
                  value={serverData.http_request_method}
                  onChange={handleSelectChange}
                >
                  <option value="get">GET</option>
                  <option value="post">POST</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="json_encoded_post"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Enable json encoded post
                </label>
                <select
                  id="json_encoded_post"
                  name="json_encoded_post"
                  value={serverData?.json_encoded_post}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="content_type"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Content Type
                </label>
                <select
                  id="content_type"
                  name="content_type"
                  value={serverData?.content_type}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="none">None</option>
                  <option value="application/json">application/json</option>
                  <option value="application/x-www-form-urlencoded">
                    application/x-www-form-urlencoded
                  </option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="content_type_accept"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Content Type Accept
                </label>
                <select
                  id="content_type_accept"
                  name="content_type_accept"
                  value={serverData.content_type_accept}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="none">None</option>
                  <option value="application/json">application/json</option>
                  <option value="application/x-www-form-urlencoded">
                    application/x-www-form-urlencoded
                  </option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="character_encoding"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Character Encoding
                </label>
                <select
                  id="character_encoding"
                  name="character_encoding"
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="none">None</option>
                  <option value="gsm-7">gsm-7</option>
                  <option value="ucs-2">ucs-2</option>
                  <option value="utf-8">utf-8</option>
                  <option value="utf-16">utf-16</option>
                  <option value="utf-32">utf-32</option>
                  <option value="ISO-8859-1">ISO-8859-1</option>
                  <option value="ucs-2be">ucs-2be</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="ssl_certificate_verification"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Ignore SSL certification verification
                </label>
                <select
                  id="ssl_certificate_verification"
                  name="ssl_certificate_verification"
                  value={serverData.ssl_certificate_verification}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="authorization"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Authorization<span className="text-red-600"> *</span>
                </label>
                <select
                  id="authorization"
                  name="authorization"
                  value={serverData.authorization}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="no_auth">
                    Authentication available on parameters
                  </option>
                  <option value="bearer_token">Bearer Token</option>
                  <option value="basic_auth">Basic auth</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="plain"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Plain<span className="text-red-600"> *</span>
                </label>
                <select
                  id="plain"
                  name="plain"
                  value={serverData.plain}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="schedule"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Schedule<span className="text-red-600"> *</span>
                </label>
                <select
                  id="schedule"
                  name="schedule"
                  value={serverData.schedule}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>

            <div className="bg-white shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%]">
              <div className="mb-4">
                <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4 ">
                  Sending Credit
                </h3>
                <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
                  The configuration setting below allows you to set a limit on
                  sms sending speed. For example, to limit sending speed to{" "}
                  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                    500 sms every 5 minutes{" "}
                  </span>{" "}
                  , you can set{" "}
                  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                    Sending limit = 500, Time value = 5, and Time unit = minute
                  </span>{" "}
                  accordingly. If you set{" "}
                  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                    SMS Per Single Request
                  </span>
                  grater than 1 then you must have to select your{" "}
                  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                    Delimiter
                  </span>{" "}
                  for multiple sms.
                </p>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="quota_value"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Sending Credit<span className="text-red-600"> *</span>
                </label>
                <input
                  id="quota_value"
                  name="quota_value"
                  type="number"
                  value={serverData.quota_value}
                  onChange={handleInputChange}
                  className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="quota_base"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Time Base<span className="text-red-600"> *</span>
                </label>
                <input
                  id="quota_base"
                  name="quota_base"
                  type="number"
                  value={serverData.quota_base}
                  onChange={handleInputChange}
                  className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="quota_unit"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Time Unit<span className="text-red-600"> *</span>
                </label>
                <select
                  id="quota_unit"
                  name="quota_unit"
                  value={serverData.quota_unit}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
                >
                  <option value="minute">Minute</option>
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                </select>
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="sms_per_request"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  SMS Per Single Unit<span className="text-red-600"> *</span>
                </label>
                <input
                  id="sms_per_request"
                  name="sms_per_request"
                  type="number"
                  value={serverData.sms_per_request}
                  onChange={handleInputChange}
                  className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
                />
              </div>

              <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                <label
                  htmlFor="multi_sms_delimiter"
                  className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]"
                >
                  Delimiter for multiple sms
                  <span className="text-red-600"> *</span>
                </label>
                <select
                  id="multi_sms_delimiter"
                  name="multi_sms_delimiter"
                  value={serverData.multi_sms_delimiter}
                  onChange={handleSelectChange}
                  className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
                >
                  <option value=",">, comma(,)</option>
                  <option value=";">; semicolon(;)</option>
                  <option value="{}">array{}</option>
                </select>
              </div>
            </div>
          </div>

          {/*right container*/}
          <div className="bg-white right shadow-md rounded-md w-[100%] md:w-[72%] h-max flex flex-col gap-4">
            <div className="header w-[100%] mt-4">
              <h2 className="text-md font-[500] text-[--accent-gray] float-left ml-4">
                Query parameters
              </h2>
              <button className=" bg-[#F08B38]  text-white px-[21px] py-[11px] text-sm mr-4 rounded-md flex h-[40px] gap-1 items-center align-center float-right">
                <TfiSave className="mr-1" />
                Update
              </button>
            </div>
            <div className="tableCont p-4 w-[100%]">
              <div className="table text-center w-[100%]">
                <table className="border-collapse center w-[100%]">
                  <thead className="bg-[#e6e6e6] w-[100%] h-[50px] border-b-[1.8px] border-solid border-[#d4cbcb] text-[--accent-gray] text-sm">
                    <tr>
                      <th className="p-[8px]"></th>
                      <th className="p-[8px]">Parameter</th>
                      <th className="p-[8px]">Value</th>
                      <th className="p-[8px]">Add on Parameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[#e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Username/API Key
                      </td>
                      <td className="">
                        <input
                          required
                          name="username_param"
                          type="text"
                          value={serverData?.username_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          required
                          name="username_value"
                          type="text"
                          value={serverData?.username_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className=""></td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Password
                      </td>
                      <td className="">
                        <input
                          name="password_param"
                          type="text"
                          value={serverData.password_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="password_value"
                          type="text"
                          value={serverData.password_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="password_status"
                          value={serverData.password_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Action
                      </td>
                      <td className="">
                        <input
                          name="action_param"
                          type="text"
                          value={serverData.action_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="action_value"
                          type="text"
                          value={serverData.action_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="action_status"
                          value={serverData.action_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Source
                      </td>
                      <td className="">
                        <input
                          name="source_param"
                          type="text"
                          value={serverData.source_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="source_value"
                          type="text"
                          value={serverData?.source_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="source_status"
                          value={serverData?.source_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Destination
                      </td>
                      <td className="">
                        <input
                          required
                          name="destination_param"
                          type="text"
                          value={serverData?.destination_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Message
                      </td>
                      <td className="">
                        <input
                          required
                          name="message_param"
                          type="text"
                          value={serverData?.message_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Unicode
                      </td>
                      <td className="">
                        <input
                          name="unicode_param"
                          type="text"
                          value={serverData?.unicode_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="unicode_value"
                          type="text"
                          value={serverData?.unicode_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="unicode_status"
                          value={serverData?.unicode_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>

                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Type/Route
                      </td>
                      <td className="">
                        <input
                          name="route_param"
                          type="text"
                          value={serverData?.route_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="route_value"
                          type="text"
                          value={serverData?.route_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="route_status"
                          value={serverData?.route_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>
                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Language
                      </td>
                      <td className="">
                        <input
                          name="language_param"
                          type="text"
                          value={serverData?.language_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="language_value"
                          type="text"
                          value={serverData?.language_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="language_status"
                          value={serverData?.language_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>
                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Custom Value 1
                      </td>
                      <td className="">
                        <input
                          name="custom_one_param"
                          type="text"
                          value={serverData?.custom_one_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="custom_one_value"
                          type="text"
                          value={serverData?.custom_one_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="custom_one_status"
                          value={serverData?.custom_one_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>
                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Custom Value 2
                      </td>
                      <td className="">
                        <input
                          name="custom_two_param"
                          value={serverData?.custom_two_param}
                          onChange={handleInputChange}
                          type="text"
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="custom_two_value"
                          type="text"
                          value={serverData?.custom_two_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="custom_two_status"
                          value={serverData?.custom_two_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>
                    <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                      <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                        Custom Value 3
                      </td>
                      <td className="">
                        <input
                          name="custom_three_param"
                          type="text"
                          value={serverData?.custom_three_param}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <input
                          name="custom_three_value"
                          type="text"
                          value={serverData?.custom_three_value}
                          onChange={handleInputChange}
                          className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                        />
                      </td>
                      <td className="">
                        <select
                          name="custom_three_status"
                          value={serverData?.custom_three_status}
                          onChange={handleSelectChange}
                          className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                        >
                          <option value="0">Set Blank</option>
                          <option value="1">Add on Parameter</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateOwnServer;
