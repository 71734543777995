import axios from "axios";
import { apiBaseUrl } from "../../utils/urls";
import { getToken } from "../../utils/tokentUtils";

export const getEomRecordData = (
  next: any,
  setAllData: (data) => void,
  showFailedToastAlert: (message) => void,
  id?: string
) => {
  const token = getToken();
  let data = JSON.stringify({
    length: 1536,
    start: next,
    orderBy: "id",
    orderDir: "DESC",
    search: "",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${apiBaseUrl}/admin/eom_records/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      const data = response?.data;
      if (data?.status === "success") {
        setAllData(data?.data);
      } else {
        showFailedToastAlert(data?.message);
      }
    })
    .catch((error) => {
      console.log(error);
      showFailedToastAlert(error?.message);
    });
};
