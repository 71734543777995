import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/tokentUtils";
import { apiBaseUrl } from "../utils/urls";
import { Formik } from "formik";

function EditPaymentGateways() {
  const { gateway } = useParams();
  const token = getToken();
  const [payment, setPayment] = useState<any>([]);
  const [jsonObj, setJsonObj] = useState<any>([]);

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${apiBaseUrl}/admin/payment-gateways/${gateway}/show`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  //function for get the data for single customer
  useEffect(() => {
    const apicall = () => {
      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          if (data?.status === "success") {
            setPayment(data.data);
            console.log(data.data);
            setJsonObj(JSON.parse(data?.data?.options));
          } else {
            showFailedToastAlert(
              `Getting single gateway error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting gateway Number error ${error?.message}`);
        });
    };
    apicall();
  }, []);

  //state of paymentGateways form
  const [form, setForm] = useState({
    name: "",
    environment: "",
    publishable_key: "",
    secret_key: "",
    merchant_id: "",
    public_key: "",
    private_key: "",
    type: "",
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    if (payment) {
      setForm((prev) => ({
        ...prev,
        name: payment?.name,
        environment: jsonObj?.environment,
        client_id: jsonObj?.client_id,
        secret: jsonObj?.secret,
        type: payment?.type,
      }));
    }
  }, [payment, jsonObj]);
  console.log(jsonObj);

  //onchange function for coverage state
  const onChangeFun = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //onchange function for selectbox of paymentGateways
  const onChangeSelectBox = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  // handle on submit form of paymentGateways
  const handleFormSubmit = async (e, data) => {
    e.preventDefault();
    try {
      const data1 = JSON.stringify(form);
      const response = await axios.put(
        `${apiBaseUrl}/admin/payment-gateways/${gateway}`,
        data1,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  //dynamic state management that is coming from api
  const handleSubmit = (values) => {
    // Generate dynamic state based on input field names
    Object.keys(values).forEach((fieldName) => {
      console.log(`Dynamic state for ${fieldName}: ${values[fieldName]}`);
      // You can use the generated state here as needed
    });
  };

  return (
    <>
      <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[60%] w-[100%] mt-6 font-sans min-h-[400px]">
        <form onSubmit={(e) => handleFormSubmit(e, form)}>
          <div className="mb-4">
            <h3 className="text-[--accent-gray] mx-4 my-4 ">{payment?.name}</h3>
            <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consectetur facilis dolore accusantium ab minus sed temporibus
              iure laboriosam itaque a ea voluptas obcaecati ipsam illo,
              reiciendis amet inventore. Quisquam, quibusdam.
              {/* <span className="rounded-sm bg-[#e6e6e6] text-pink-400"></span>{" "} */}
            </p>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Name<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value={form?.name}
              onChange={(e) => onChangeFun(e, "name")}
              required
            />
            <span className="text-[#4848e6] mt-1 text-[12px]">
              You can rename payment name with your own
            </span>
          </div>{" "}
          <Formik
            initialValues={form}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <form onSubmit={handleSubmit}>
                {Object.keys(jsonObj).map((objkey) => {
                  if (objkey === "environment") {
                    return (
                      <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                        <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                          Environment<span className="text-red-600"> *</span>
                        </label>
                        <select
                          className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
                          name="environment"
                          id=""
                          value={form?.environment}
                          onChange={(e) => onChangeSelectBox(e, "environment")}
                        >
                          <option
                            value="sandbox"
                            selected={
                              jsonObj?.environment === "sandbox" ? true : false
                            }
                          >
                            Sandbox
                          </option>
                          <option
                            value="production"
                            selected={
                              jsonObj?.environment === "production"
                                ? true
                                : false
                            }
                          >
                            Production
                          </option>
                        </select>
                      </div>
                    );
                  } else {
                    return (
                      <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
                        <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                          {objkey.charAt(0).toUpperCase() + objkey.slice(1)}
                          <span className="text-red-600"> *</span>
                        </label>
                        <input
                          type="text"
                          className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
                          value={jsonObj[objkey]}
                          onChange={(e) => onChangeFun(e, objkey)}
                          name={objkey.charAt(0)}
                          required
                        />
                      </div>
                    );
                  }
                })}
              </form>
            )}
          </Formik>
          <div className=" mb-6 ml-4">
            <button
              type="submit"
              className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
            >
              <TfiSave className="mr-1" />
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditPaymentGateways;
