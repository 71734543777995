import { RiAccountCircleFill } from "react-icons/ri";
import { HiOutlineUpload } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TfiSave } from "react-icons/tfi";
import useGetQuery from "../../hooks/useGetQuery";
import { useState, useEffect, useRef } from "react";
import TimeZonePicker from "../../TimeZonePicker";
import Languages from "../Languages";
import axios from "axios";
import { apiBaseUrl, imageBaseUrl } from "../../utils/urls";
import { Button, Modal } from "antd";

import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import { getToken } from "../../utils/tokentUtils";
import DialogBox from "../DialogBox";
import { FiAlertCircle } from "react-icons/fi";
import ImageUploadComponent from "./ImageUploadComponent";

const AccountSection = () => {
  const token = getToken();

  // ===========to get accountsData ==============
  const { getQueryData: accountsData } = useGetQuery(
    `/users/account`,
    `/users/account`
  );

  // ==============to get avatar of admin==========
  const { getQueryData: avatarData } = useGetQuery(
    `/users/avatar`,
    `/users/avatar`
  );

  // state variable for accountSection form
  const [accountForm, setAccountForm] = useState({
    _method: "PATCH",
    _token: "CFKuZ0TOg0Q2XcJePbFKAM1bZVz46Sox1HiQEMPS",
    first_name: "",
    last_name: "",
    email: "",
    timezone: "",
    locale: "",
  });

  // to set intial value when components render
  useEffect(() => {
    setAccountForm((prev) => ({
      ...prev,
      first_name: accountsData?.user?.first_name,
      last_name: accountsData?.user?.last_name,
      email: accountsData?.user?.email,
      timezone: accountsData?.user?.timezone,
      locale: accountsData?.languages[0].name,
    }));
  }, [accountsData]);

  console.log(accountsData, "accountsData");

  // to handle onchange function for select element
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setAccountForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // this is callback function for TimeZone component.
  const getTimeZone = (timezone) => {
    setAccountForm((prevForm1) => ({
      ...prevForm1,
      timezone: timezone,
    }));
  };

  // handle AccountSection form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = JSON.stringify(accountForm);
    try {
      const response = await axios.patch(
        `${apiBaseUrl}/users/account/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  // =============admin avatar===============
  const [avatar, setAvatar] = useState<any>();
  console.log(avatarData?.image, "rel-path");
  console.log(`${imageBaseUrl}${avatarData?.image}`, "ssssss");

  //=============Upload-&-remove-Modal=============
  const [isModalOpen, setIsModalOpen] = useState(false); // state for upload modal
  const [isModalOpen1, setIsModalOpen1] = useState(false); // state for remove modal

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    console.log("its working");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // -----------for remove modal--------
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (URL && URL.createObjectURL) {
        setAvatar(URL.createObjectURL(file));
      }
      setIsModalOpen(false);
    } else {
      console.log("createObjectUrl error");
    }
  };

  const handleImageRemove = () => {
    setAvatar(null);
    setIsModalOpen1(false);
  };

  console.log(avatar, "aavvttrr");

  // ============================================

  // ========== To handle image upload and remove===========
  // const [selectedImage, setSelectedImage] = useState<any>(null);
  // const fileInputRef = useRef<any>(null);

  // const handleButtonClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     if (URL && URL.createObjectURL) {
  //       setSelectedImage(URL.createObjectURL(file));
  //     }
  //   } else {
  //     console.log("createObjectUrl error");
  //   }
  // };

  // const handleImageRemove = (e) => {
  //   setSelectedImage(null);
  // };
  // ========================================================

  return (
    <>
      <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
        <ImageUploadComponent />
        {/* <div className="top flex gap-4 items-center">
          <div>
            <img
              className="h-[300px] w-[300px] "
              src={avatar}
              alt="avatar"
            />
          </div>
          <div>
            <div className="flex gap-4 my-2">
              <button
                onClick={showModal}
                className="bg-[#F08B38] hover:shadow-lg text-white px-3 py-1 rounded-md flex items-center gap-1 "
              >
                <HiOutlineUpload />
                Upload
              </button>

              <Modal
                title="Upload image"
                open={isModalOpen}
                centered={true}
                onCancel={handleCancel}
                footer={null}
              >
                <div className="flex gap-2 mb-6 flex-col">
                  <label
                    className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                    htmlFor="app_logo"
                  >
                    Image
                  </label>
                  <input
                    id="app_logo"
                    name="app_logo"
                    className="bg-white cursor-pointer outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                  />
                  <span className="text-[#7367F0] text-[11px] ">
                    Recommended size: Width 300px ☓ Height 300px
                  </span>
                  <div className="flex justify-end">
                    <button
                      onClick={handleOk}
                      className="bg-[#F08B38] hover:shadow-lg text-white px-3 py-1 rounded-md flex items-center gap-1 "
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </Modal>

              <button
                onClick={showModal1}
                className="bg-[#EA5455] hover:shadow-lg text-white px-3 py-1 rounded-md flex items-center gap-1 "
              >
                <RiDeleteBinLine />
                remove
              </button>

              <Modal
                open={isModalOpen1}
                onOk={handleOk1}
                footer={null}
                centered={true}
                onCancel={handleCancel1}
              >
                <div className="flex flex-col gap-4 justify-center items-center">
                  <FiAlertCircle className="text-[#F9C395]" size={80} />
                  <label className="text-2xl font-[600] text-[var(--accent-gray)] ">
                    Are you sure ?
                  </label>
                  <p className="text-[var(--accent-gray)] text-xl ">
                    You would not be able to revert this!
                  </p>
                  <div className="flex items-center justify-center gap-2">
                    <button
                      onClick={handleImageRemove}
                      className="bg-[var(--accent-orange)] border-2 border-[#f99004] text-[#fff] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] font-[500]  "
                    >
                      Yes,delete it!
                    </button>
                    <button
                      onClick={handleCancel1}
                      className=" font-[500] bg-white border-2 border-[#ea5455] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] text-[#EA5455] "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <div>
              <p className="form-label-size text-[var(--accent-gray)] ">
                Recommended size: Width 300px ☓ Height 300px
              </p>
            </div>
          </div>
        </div> */}

        {/* -------form part 1----- */}
        <div className="bottom  p-2">
          <form action="" className="flex gap-4">
            <div className="left w-[50%]">
              <div className="w-full ">
                <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
                  Email<span className="text-red-600"> *</span>
                </label>
                <input
                  value={accountForm?.email}
                  type="email"
                  className="bg-white outline-1 w-full outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400] "
                  name="email"
                  // onChange={handleForm1Change}
                />
              </div>

              <div className="right-sub flex  gap-2 mt-[7px] mb-[16px] items-center  ">
                <div className="flex flex-col items-center w-[50%]  gap-1">
                  <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                    First name<span className="text-red-600"> *</span>
                  </label>
                  <input
                    value={accountForm?.first_name}
                    type="text"
                    className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                    name="firstName"
                    // onChange={handleForm1Change}
                  />
                </div>
                <div className="flex flex-col items-center w-[50%] gap-1 text-left">
                  <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                    Last name
                  </label>
                  <input
                    value={accountForm?.last_name}
                    type="text"
                    className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                    name="lastName"
                    // onChange={handleForm1Change}
                  />
                </div>
              </div>
            </div>
            <div className="Right w-[50%]">
              <div className="flex flex-col mb-[16px] ">
                <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                  Timezone<span className="text-red-600"> *</span>
                </label>
                {/* <input
                  type="text"
                  // value={form1.timezone}
                  className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                  name="timezone"
                  // onChange={handleForm1Change}
                /> */}

                <TimeZonePicker
                  data={accountForm.timezone}
                  getTimeZone={getTimeZone}
                />
              </div>
              <div className="flex flex-col mb-[16px] ">
                <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start  ">
                  Language<span className="text-red-600"> *</span>
                </label>
                <select
                  name="locale"
                  onChange={onChangeFun}
                  value={accountForm?.locale}
                  className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem]"
                >
                  {accountsData?.languages?.map((option, key) => (
                    <option key={key} value={option?.code}>
                      {option?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSubmit}
              className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
            >
              <TfiSave />
              save changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSection;
