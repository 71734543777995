import React from "react";
import DataTable from "react-data-table-component";
import DialogBox from "./DialogBox";
import { NavLink } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { Switch } from "antd";
import { useState } from "react";
import FormSkeleton from "./FormSkeleton";
import importdata from "../assets/importdata.png";
import addnew from "../assets/addnew.png";

function SenderIdPlan() {
  const columns: any = [
    {
      name: "SENDER ID",
      selector: (row) => (
        <div className="flex gap-[10px] cursor-pointer">
          <p className="text-[14px] text-[#5F6368]">{row.sender_id}</p>
        </div>
      ),
    },
    {
      name: "ASSIGN TO",
      selector: (row) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full">
              <img src={row.avatar} alt="" className="cursor-pointer" />
            </div>
            <div>
              <p
                className="text-[14px] text-[#5F6368]"
                dangerouslySetInnerHTML={{ __html: row.user_id }}
              ></p>
              <p className="text-[11px] text-[#5F6368]">{row.email}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
    },
    {
      name: "Status",
      selector: (row) => (
        <Switch
          checked={row.status === "active" ? true : false}
          // onChange={onChange}
        />
      ),
      // cell: row => <> <label className="switch">
      //     <input type="checkbox" checked />
      //     <span className="slider round"></span>
      // </label></>
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-4 ">
          <DialogBox title="delete" deleteUrl={`/admin/custome/${row.id}`} />
          <NavLink to={`/admin/senderid/${row.id}/show`}>
            <FiEdit className="text-[#7367F0] cursor-pointer" size={20} />
          </NavLink>
        </div>
      ),
    },
  ];

  const [searchinput, setSearchInput] = useState<any>("");
  const [dataForTable, setTable] = useState<any>();

  // data that have been returned from pagination.

  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  console.log(dataForTable,'data')

  const data = dataForTable
    ?.filter((val) => {
      if (searchinput === "") {
        return val;
      } else if (val.email.toLowerCase().includes(searchinput.toLowerCase())) {
        return val;
      }
      return val;
    })
    .map((val) => ({
      id: val.uid,
      sender_id: val.sender_id,
      email: val.email,
      avatar: val.avatar,
      price: val.price,
      status: val.status,
      user_id: val.user_id,
    }));

  return (
    <div>
      <div className="flex gap-[15px] flex-wrap my-6 ml-2">
        <div className="flex items-center bg-[#F1602B] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
          <p className="text-white text-[12px] leading-[14.06px]">Action</p>{" "}
          <img src={importdata} alt="" />
        </div>

        <NavLink to="/admin/senderid/create-plan">
          <div className="flex items-center bg-[#2aa300]  gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-white">Create</p>
            <img src={addnew} alt="" />
          </div>
        </NavLink>
      </div>
      <FormSkeleton titles={["PRICE", "RENEW", "ACTIONS"]} />:{" "}
      <DataTable
        columns={columns}
        data={data}
        selectableRows
        className="px-[10px] h-[550px]"
      />
    </div>
  );
}

export default SenderIdPlan;
